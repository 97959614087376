import { styled } from '@mui/material/styles';
import { Box, Typography, TextField, Button, IconButton } from '@mui/material';

export const ContactContainer = styled(Box)(({ theme }) => ({
  width: '98vw',
  height: '100vh',
  marginTop: '4rem',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down(500)]: {
    height: 'auto',
    width: '95vw',
    marginTop: '5rem',
    marginBottom: '2rem',
  },
}));

export const InnerBox = styled(Box)(({ theme }) => ({
  width: '90vw',
  height: '90vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down(500)]: {
    height: '100%',
    width: '100%',
  },
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '1rem',
  color: 'black',
  [theme.breakpoints.down(500)]: {
    width: '80%',
  },
}));

export const StyledContentBox = styled(Box)(({ theme }) => ({
    width: '90%',
    height: '78%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down(500)]: {
      flexDirection: 'column',
      width: '100%',
    },
  }));

export const IconTextBox = styled(Box)(({ theme }) => ({
  marginTop: '4rem',
  width: '40%',
  height: '80%',
  display: 'flex',
  color: 'black',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '2rem',
  
}));

export const ContactDetail = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '3rem',
  marginLeft: 0,
  [theme.breakpoints.down(500)]: {
    width: '80vw',
    marginLeft: '-5rem',
  }
}));

export const IconWrapper = styled(IconButton)({
  fontSize: 40,
  color: '#fff',
  backgroundColor: '#008080',
  borderRadius: '50%',
});

export const FormBox = styled(Box)(({ theme }) => ({
  width: '30vw',
  height: '65vh',
  backgroundColor: 'white',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  marginTop: 0,
  marginBottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down(1000)]: {
    width: '40vw',
    height: '50vh',
    marginTop: '7rem',
  },
  [theme.breakpoints.down(500)]: {
    width: '70vw',
    height: '60vh',
  },
}));

export const SendButton = styled(Button)({
  backgroundColor: '#008080',
  color: 'white',
  marginTop: '2rem',
});
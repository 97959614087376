import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid } from '@mui/material';

export const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#333',
  color: '#fff',
  width: '98vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down(1000)]: {
    width: '97vw',
  },
  [theme.breakpoints.down(500)]: {
    width: '95vw',
  },
}));

export const FooterGridContainer = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    width: '100%',
  },
}));

export const FooterGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
}));

export const FooterLink = styled(Link)(({ theme }) => ({
  my: theme.spacing(-0.5),
  color: 'inherit',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

export const FooterBox = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

export const FooterTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontSize: '1rem',
  '&.MuiTypography-h6': {
        fontSize: '1.5rem',
    },

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1),
  },
}));

export const FooterFormContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
}));

export const FooterInput = styled('input')(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  border: 'none',
  width: '70%',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    marginBottom: theme.spacing(1),
  },
}));

export const FooterButton = styled('button')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: 'none',
  backgroundColor: '#555',
  color: '#fff',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    width: '50%',
    padding: theme.spacing(1),
  },
}));
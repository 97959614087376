// data.js

// Example API URL (replace with your actual API endpoint)
// const apiUrl = 'http://127.0.0.1:5000/get-subscriptions';
const apiUrl = 'https://api.admissionfirst.in/get-subscriptions';

// Function to fetch data from the API
async function fetchPlansData() {
  try {
    // Make a GET request to fetch data from the API
    const response = await fetch(apiUrl);

    // Check if the response is OK (status code 200–299)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }                          

    // Parse the response data as JSON
    const fetchedData = await response.json();

    // Log the fetched data (Optional)
    console.log('Fetched Data:', fetchedData);

    // Return the data so it can be sent to other components
    return fetchedData;

  } catch (error) {
    // Handle any errors that occur during the request
    console.error('Error fetching data from API:', error);
  }
}

console.log(fetchPlansData)

// Export the fetch function so it can be used in other components
export { fetchPlansData };
import React, { useState } from "react";
import { TextField, Button, Box, Snackbar, Alert, Typography, CssBaseline, Paper } from "@mui/material";
import { BackgroundGrid, MainBox } from '../signIn_/SignInStyles';
import { Send } from '@mui/icons-material';
import axios from "axios";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.admissionfirst.in/forgot-password",
        { email },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        setError(null);
      } else {
        setError("Email not found.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (

    <MainBox component="main">
      <CssBaseline />
      <BackgroundGrid item xs={false} sm={4} md={7} />

      <Paper elevation={4} sx={{ width: '30rem', p: 2 }} >

    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, mx: "auto", mt: 4 }}>
      <Typography variant="h5" align="center">Forgot Password</Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 2 }}>
        Send Reset Link
        <Send sx={{ ml: 2, fontSize: '1.2rem' }} />
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={error ? "error" : "success"} sx={{ width: "100%" }}>
          {error || "Password reset email sent!"}
        </Alert>
      </Snackbar>
    </Box>
    </Paper>
    </MainBox>
  );
}

export default ForgotPassword;

import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import bgImage from '../../assets/WhatsApp Image 2024-04-06 at 14.30.37_61e3ae87.jpg';

// Styled Components
const RootContainer = styled(Box)({
  width: '98vw',
  minHeight: '100vh',
  marginTop: '4.6rem',
  backgroundColor: 'white',
  overflowX: 'hidden',
});

const HeaderContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: 'white',
});

const BackgroundImage = styled(Box)({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  backgroundColor: '#000',
  filter: 'brightness(0.6)', // Darkens image for overlay effect
});

const TextOverlay = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
});

const DiagonalSeparator = styled(Box)({
  position: 'absolute',
  top: '-3.7rem',
  left: 0,
  width: '100%',
  height: '100px',
  background: 'linear-gradient(to right, #008080, transparent)',
  clipPath: 'polygon(0 0, 100% 100%, 0 100%)',
  zIndex: 1,
});

const SectionContent = styled(Box)({
  position: 'relative',
  zIndex: 2,
  padding: '6rem 2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const GradientBackground = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: '2rem 0',
  padding: '6rem 0',
  backgroundColor: '#f0f8ff',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  [theme.breakpoints.down("900")]: {
    width: '85vw',
  },
  [theme.breakpoints.down("500")]: {
    width: '70vw',
  },
  [theme.breakpoints.down("400")]: {
    width: '66vw',
  },
}));

const CircleDecoration = styled(Box)({
  position: 'absolute',
  top: '-40px',
  left: '-40px',
  width: '100px',
  height: '100px',
  backgroundColor: '#008080',
  borderRadius: '50%',
  zIndex: 1,
  opacity: 0.5,
});

const VerticalLineDecoration = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  width: '5px',
  backgroundColor: '#008080',
  zIndex: 1,
});

const LegalInfoContainer = styled(Box)(({ theme }) => ({
  width: '91.6vw',
  margin: '2rem 0',
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
  position: 'relative',
  textAlign: 'right',
  padding: '3rem',
  [theme.breakpoints.down("900")]: {
    width: '85vw',
  },
  [theme.breakpoints.down("500")]: {
    width: '70vw',
  },
  [theme.breakpoints.down("400")]: {
    width: '67vw',
  },
}));

const About = () => {
  return (
    <RootContainer>
      {/* Header Image with Overlay */}
      <HeaderContainer sx={{ height: { xs: '40vh', md: '80vh' } }}>
        <BackgroundImage />
        {/* Text Overlay */}
        <TextOverlay>
          <Typography variant="h2" sx={{ fontWeight: 'bold', color: 'white', mb: 2 }}>
            About Us
          </Typography>
          <Typography variant="h6" sx={{ color: '#e0f7fa' }}>
            Helping students build their future with expert guidance.
          </Typography>
        </TextOverlay>
      </HeaderContainer>

      {/* Vision & Mission Section with Diagonal Separation */}
      <Box sx={{ position: 'relative', mt: -5 }}>
        <DiagonalSeparator />
        {/* Content */}
        <SectionContent>
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#008080', my: 4 }}>
            Vision & Mission
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ color: 'black', textAlign: 'justify', mb: 2 }}>
                "Empowering every student to achieve their highest potential by providing personalized, comprehensive, and expert college admission counseling, ensuring they gain admission to their ideal schools and build a foundation for lifelong success."
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ color: 'black', mb: 2 }}>
                <strong>1. Personalized Guidance:</strong> Tailored counseling services that meet the unique needs of each student.
              </Typography>
              <Typography variant="body1" sx={{ color: 'black', mb: 2 }}>
                <strong>2. Expert Knowledge:</strong> Current information on admissions, scholarships, and financial aid.
              </Typography>
              <Typography variant="body1" sx={{ color: 'black', mb: 2 }}>
                <strong>3. Holistic Support:</strong> Supporting both academic and emotional growth.
              </Typography>
            </Grid>
          </Grid>
        </SectionContent>
      </Box>

      {/* Objectives Section with Gradient Background */}
      <GradientBackground>
        <CircleDecoration />
        <Grid container spacing={4} sx={{ width: { xs: '90%', md: '70%' }, zIndex: 2 }}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#008080' }}>
                Objectives
              </Typography>
              <Typography variant="body1" sx={{ color: 'black', textAlign: 'justify' }}>
                Improve the acceptance rates of students into their preferred colleges and universities. Continuously track success rates and enhance strategies. Develop a robust library of resources to guide students.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </GradientBackground>

      {/* Legal Info Section with Vertical Line Accent */}
      <LegalInfoContainer>
        <VerticalLineDecoration />
        <Box sx={{ width: { xs: '90%', md: '60%' }, zIndex: 2 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: '#008080' }}>
            Legal Info
          </Typography>
          <Typography variant="body1" sx={{ color: 'black', mb: 2 }}>
            Stay updated with federal and state regulations regarding educational counseling and admissions.
          </Typography>
          <Typography variant="body1" sx={{ color: 'black', mb: 2 }}>
            Ensure compliance with laws and ethical guidelines.
          </Typography>
          <Typography variant="body1" sx={{ color: 'black' }}>
            Clearly communicate services and fees.
          </Typography>
        </Box>
      </LegalInfoContainer>
    </RootContainer>
  );
};

export default About;

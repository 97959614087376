import {
  Autocomplete,
  Box,
  Button,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Close, CloudUploadOutlined, PictureAsPdf } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import axios from "axios";

const StyledTextField = styled(TextField)(({ theme, disabled }) => ({
  "& .MuiInputLabel-root": {
    color: "#006666",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#006666",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#008080 !important",
      borderWidth: "1px",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#008080 !important",
    borderWidth: "1px",
  },
}));

function SetDocuments({ handleClickOpen, plans, handleClose, open }) {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null); // State to store selected file type

  const titles = plans.map((plan) => plan.title);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    console.log("Selected file:", selectedFile); // Log the selected file
  };

  const handleFileUpload = async () => {
    if (!selectedPlan || !file || !fileType) {
      alert("Please select a plan, file type, and a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_type", fileType);

    try {
      const response = await axios.post(
        `https://api.admissionfirst.in/admin/add_documents/${selectedPlan}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data.message);

      if (response.status === 201) {
        alert("File uploaded successfully");
      }
    } catch (error) {
      console.error(
        "Error uploading file:",
        error.response ? error.response.data.message : error.message
      );
      alert(
        "Error uploading file: " +
          (error.response ? error.response.data.message : error.message)
      );
    }
  };

  // Function to handle button click and set file type
  const handleOpenDialogWithFileType = (type) => {
    setFileType(type);
    handleClickOpen(); // Open the dialog after setting the file type
  };

  return (
    <Box sx={{ width: "70vw", height: "auto", m: 1 }}>
      <Paper
        elevation={8}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "left", m: 2 }}
        >
          <Typography variant="h6" sx={{ color: "#006666" }}>
            Set Documents:
          </Typography>
        </Box>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Autocomplete
            disablePortal
            options={titles}
            onChange={(event, newValue) => setSelectedPlan(newValue)}
            sx={{ width: "90%" }}
            renderInput={(params) => (
              <StyledTextField {...params} label="Select Plan" />
            )}
          />
        </Box>
        <Box
          sx={{
            width: "95%",
            m: 2,
            display: "grid",
            gridTemplateColumns: "auto auto auto auto auto",
            gap: 3,
          }}
        >
          {/* Document category buttons, each with its own file type */}
          {[
            { label: "Announcements", type: "announcements" },
            { label: "Admission Process", type: "admission_process" },
            { label: "Required Documents", type: "required_documents" },
            { label: "Eligibility Criteria", type: "eligibility_criteria" },
            { label: "Seat Matrix", type: "seat_matrix" },
            { label: "Merit List", type: "merit_list" },
            { label: "Previous Year Cut-Off", type: "previous_year_cutoff" },
            { label: "Round Alerts", type: "round_alerts" },
            { label: "Institutes", type: "institutes" },
            { label: "Fee Structure", type: "fee_structure" },
          ].map(({ label, type }, index) => (
            <Button
              key={index}
              variant="contained"
              onClick={() => handleOpenDialogWithFileType(type)}
              sx={{
                bgcolor: "#008080",
                '&:hover': {
                  bgcolor: '#006666'
                }
              }}
            >
              {label}
            </Button>
          ))}
        </Box>

        <Dialog
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: {
              width: "30vw",
              height: "60vh",
            },
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          <DialogContent
            dividers
            sx={{
              gap: 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "20vw",
                height: "30vh",
                border: "2px dashed #008080",
                bgcolor: "#f0f0f0",
              }}
            >
              <Button
                component="label"
                sx={{
                  width: "20vw",
                  height: "30vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!file ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CloudUploadOutlined
                      sx={{ fontSize: "5rem", color: "#006666" }}
                    />  
                    <Typography
                      variant="h7"
                      sx={{ color: "#006666", fontWeight: "600" }}
                    >
                      Click Here To Upload File
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <PictureAsPdf sx={{ fontSize: "5rem", color: "#006666" }} />
                    <Typography sx={{ color: "#006666" }}>
                      {fileType}
                    </Typography>
                  </Box>
                )}

                <input type="file" hidden onChange={handleFileChange} />
              </Button>
            </Box>
            <Box
              sx={{
                width: "20vw",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{ bgcolor: "#008080", "&:hover": { bgcolor: "#006666" } }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                autoFocus
                onClick={handleFileUpload}
                sx={{ bgcolor: "#008080", "&:hover": { bgcolor: "#006666" } }}
              >
                Upload
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Paper>
    </Box>
  );
}

export default SetDocuments;

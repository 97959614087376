import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import AdminPanel from "../../component/adminComps_/adminPanel_/AdminPanel";
import UserStatistics from "../../component/adminComps_/userStatistics_/UserStatistics";
import PurchaseRequest from "../../component/adminComps_/purchaseRequests_/PurchaseRequests";
import SetUpdates from "../../component/adminComps_/setUpdates_/SetUpdates";
import SetDocuments from "../../component/adminComps_/setDocuments_/SetDocuments";
// import UploadFile from "../../component/adminComps_/uploadFile_/UploadFile";
import axios from "axios";
import { fetchPlansData } from "../../component/data_/SubPlanData";
// import CardData from '../../component/subPlanCard_/CardData';
import AdminData from "./AdminData";

function Admin() {
  const [open, setOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [error, setError] = useState(null);

  const [plans, setPlans] = useState([]); // State to hold the fetched plans

  // Fetch the plans data when the component mounts
  useEffect(() => {
    async function getPlans() {
      try {
        // Check if the page has been refreshed already
        const hasRefreshed = sessionStorage.getItem("hasRefreshed");

        // If user is logged in and page hasn't been refreshed, refresh once
        if (!hasRefreshed) {
          sessionStorage.setItem("hasRefreshed", "true"); // Mark refresh as done
          setTimeout(() => {
            window.location.reload(); // Refresh the page once
          }, 1000); // 1000 milliseconds = 1 second
        }

        const fetchedPlans = await fetchPlansData(); // Fetch data from API
        setPlans(fetchedPlans); // Set the fetched plans to the state
        console.log(plans);
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    }

    getPlans(); // Call the function to fetch plans
  }, []); // Empty dependency array to ensure it only runs once on mount

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchAdminDashboardData = async () => {
      try {
        const response = await axios.get(
          "https://api.admissionfirst.in/admin_dashboard"
        );
        setDashboardData(response.data); // Assuming you're storing it in the state
      } catch (error) {
        console.error("Error fetching admin dashboard data:", error);
        setError("Failed to load dashboard data. Please try again."); // Set error state
      }
    };

    fetchAdminDashboardData();
  }, []);

  console.log("this is admin dashboard", dashboardData); // Log the dashboard data

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: "5rem" }}>
      <Box>
        <AdminPanel />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            {dashboardData && (
              <>
                <UserStatistics dashboardData={dashboardData} />
                <PurchaseRequest dashboardData={dashboardData} />
                <SetUpdates plans={plans} />
                <SetDocuments
                  plans={plans}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  open={open}
                />
                {/* <UploadFile handleClose={handleClose} open={open} /> */}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default Admin;

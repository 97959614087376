import { styled } from '@mui/material/styles';
import { Box, Card, TextField, Typography } from '@mui/material';

export const PaymentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '6rem',
  marginBottom: '2rem',
  width: '98vw',
  minHeight: '80vh',
  [theme.breakpoints.down(1000)]: {
    flexDirection: 'column', // Stack content vertically for tablets
    // padding: theme.spacing(2),
    alignItems: 'center',
  },
  [theme.breakpoints.down(500)]: {
    justifyContent: 'center', // Center content for smaller screens
    // padding: theme.spacing(1),
    width: '90vw',
  },
}));

export const PlanContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '30%',
  [theme.breakpoints.down('md')]: {
    width: '50%', // Adjust width for tablets
  },
  [theme.breakpoints.down(500)]: {
    width: '90%', // Adjust width for small mobile screens
  },
}));

export const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40%',
  marginRight: '10rem',
  padding: theme.spacing(3),
  boxShadow: theme.shadows[3],
  backgroundColor: '#f0f0f0',
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down(1000)]: {
    maxWidth: '100%',
    marginRight: 0, // Remove the margin on smaller screens
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down(500)]: {
    width: '75vw',
    // padding: theme.spacing(1), // Reduce padding for smaller mobile screens
    marginTop: theme.spacing(5),
  },
}));

export const QRImage = styled(Card)(({ theme }) => ({
  width: '200px',
  height: '200px',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '150px',
    height: '150px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '120px', // Further reduce size for smaller screens
    height: '120px',
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%', // Ensure full-width on all screens
  '& .MuiInputLabel-root': {
    color: '#006666',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#006666',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#008080 !important',
      borderWidth: '1px',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#008080 !important',
    borderWidth: '1px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem', // Adjust font size for mobile
  },
}));

export const ResponseBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(2),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1), // Reduce padding on smaller screens
  },
}));

export const SuccessMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem', // Reduce font size for smaller screens
  },
}));

export const ActivationMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem', // Reduce font size for smaller screens
  },
}));

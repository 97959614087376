import React, { useState } from 'react';
import { Box, Typography, CardContent, IconButton, Card, CardMedia, Grid, useTheme, useMediaQuery } from '@mui/material';
import Divider from '@mui/joy/Divider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/system';
import reviewers from './ReviewersData'
import { Container, Title, CarouselBox, StyledCard, Media, TriangleBox, Content, ReviewText } from './ReviewsStyles';


function Reviews() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme(); // Access the theme object
  const isMobile = useMediaQuery(theme.breakpoints.down('600')); // Use theme breakpoints
  const isTablet = useMediaQuery(theme.breakpoints.between('600', '1000')); // Use theme breakpoints

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviewers.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + reviewers.length) % reviewers.length);
  };

  const visibleItems = isMobile ?
                          [ reviewers[currentIndex], ]
                          :
                        isTablet ?
                          [
                            reviewers[currentIndex],
                            reviewers[(currentIndex + 1) % reviewers.length]
                          ]
                          :
                          [
                            reviewers[currentIndex],
                            reviewers[(currentIndex + 1) % reviewers.length],
                            reviewers[(currentIndex + 2) % reviewers.length],
                          ];

  return (
    <Container>
      {/* <Divider sx={{ width: '90vw', height: '.2rem' }} /> */}
      <Title>Our Happy Member Experience</Title>
      <CarouselBox container spacing={2} gap={11}>
        <IconButton onClick={handlePrev}>
          <ArrowBackIosIcon />
        </IconButton>
        {visibleItems.map((item, index) => (
          <Grid item key={index}>
            <StyledCard>
              <Media
                component="img"
                image={item.img}
                alt={`Reviewer ${index + 1}`}
              />
              <TriangleBox />
              <Content>
                <ReviewText variant="body2">
                  {item.review}
                </ReviewText>
              </Content>
            </StyledCard>
          </Grid>
        ))}
        <IconButton onClick={handleNext}>
          <ArrowForwardIosIcon />
        </IconButton>
      </CarouselBox>
      {/* <Divider sx={{ width: '90vw', height: '.2rem' }} /> */}
    </Container>
  );
}

export default Reviews;
import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";

function Notifications() {
  // Retrieve user_id from sessionStorage and parse it if necessary
  const userdataString = sessionStorage.getItem("user");
  const userdata = JSON.parse(userdataString); // Parse the JSON string

  // Access user ID
  const userId = userdata?.id; // Use optional chaining to avoid errors if userdata is null

  const data = {
    user_id: userId,
  };

  //Set Notifications
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `https://api.admissionfirst.in/user/notifications/${userId}`
        );
        setNotifications(response.data);
      } catch (err) {
        setError(
          err.response
            ? err.response.data.message
            : "Error fetching notifications"
        );
      }
    };

    fetchNotifications();
  }, []);

  return (
    <Box
      sx={{
        width: "70vw",
        height: "auto",
        m: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={8}
        sx={{ width: "100%", height: "100%", borderRadius: "12px" }}
      >
        <Box sx={{ m: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "600",
              color: "#006666",
              mb: 2,
            }}
          >
            Notifications :
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ m: 5 }}>
          {notifications.map((item, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography
                variant="body1"
                color={item.is_read ? "text.secondary" : "text.primary"}
              >
                {item.message}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.subscription}
              </Typography>
            </Box>
          ))}
        </Box>
      </Paper>
    </Box>
  );
}

export default Notifications;
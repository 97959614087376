// // src/components/UserStatistics.js
import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Paper,
  Button,
  Dialog,
  IconButton,
  DialogContent,
  Divider,
  DialogTitle,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import { Close, CloudUploadOutlined, KeyboardDoubleArrowRight } from "@mui/icons-material";

const StatBox = ({ iconColor, statIcon, title, stat, handleClickOpen }) => {
  return (
    <Paper
      elevation={8}
      sx={{
        width: "30%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Box>
          <Box color={iconColor}>{statIcon}</Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">{title}</Typography>
          <Typography variant="h4">{stat}</Typography>
        </Box>
      </Box>

      <Box>
        <Button
          variant="outlined"
          sx={{ display: "flex", alignItems: "center" }}
          onClick={handleClickOpen}
        >
          See All
          <KeyboardDoubleArrowRight sx={{ fontSize: "1.3rem", ml: "0.5rem" }} />
        </Button>
      </Box>
    </Paper>
  );
};


function UserStatistics({ dashboardData }) {

  const [open, setOpen] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: "70vw", height: "25vh", my: 1 }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          gap: 7,
        }}
      >
        <StatBox
          iconColor={"#b5b500"}
          statIcon={<PeopleIcon sx={{ fontSize: "5rem" }} />}
          title={"Total Users"}
          stat={dashboardData["total_users"]}
          handleClickOpen={handleClickOpen}
        />
        <StatBox
          iconColor={"#00ff00"}
          statIcon={<PeopleIcon sx={{ fontSize: "5rem" }} />}
          title={"Subscribers"}
          stat={dashboardData["total_premium_users"]}
          handleClickOpen={handleClickOpen}
        />
        <StatBox
          iconColor={"#ff0000"}
          statIcon={<PeopleIcon sx={{ fontSize: "5rem" }} />}
          title={"Non-Subscribers"}
          stat={dashboardData["total_non_subscribers"]}
          handleClickOpen={handleClickOpen}
        />
      </Box>


      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth='lg'
        PaperProps={{
          sx: {
            width: "80vw",
            height: '90vh'
          }
        }}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >

        <DialogTitle>User Details</DialogTitle>

        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close/>
        </IconButton>

        <Divider/>

        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }} >
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }} >
            <Typography sx={{ fontWeight: '600', width: '18vw', display: 'flex', justifyContent: 'center' }} >User Name</Typography>
            <Typography sx={{ fontWeight: '600', width: '18vw', display: 'flex', justifyContent: 'center' }} >Subscription Name</Typography>
            <Typography sx={{ fontWeight: '600', width: '18vw', display: 'flex', justifyContent: 'center' }} >Validity</Typography>
            <Typography sx={{ fontWeight: '600', width: '18vw', display: 'flex', justifyContent: 'center' }} >Action</Typography>
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }} >
            <Typography sx={{ fontWeight: '600', width: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Suraj Doke</Typography>
            <Typography sx={{ fontWeight: '600', width: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >NA</Typography>
            <Typography sx={{ fontWeight: '600', width: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >NA</Typography>
            <Box sx={{ fontWeight: '600', width: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button variant="contained" >Remove</Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>


    </Box>
  );
}

export default UserStatistics;

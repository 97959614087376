import A1 from '../../assets/A1.png';
import A2 from '../../assets/A2.jpg';
import A3 from '../../assets/A3.png';
import A5 from '../../assets/A5.jpg';
import A6 from '../../assets/A6.jpg';
import A8 from '../../assets/A8.jpg';

const reviewers = [
  {
    img: A1,
    review: "Give All Related Required Documents & Certificate Of Formats For Your Enrolled Programs"
  },
  {
    img: A2,
    review: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ducimus, molestiae.'
  },
  {
    img: A3,
    review: 'Lorem ipsum dolor sit amet, quasi. Natus, deserunt? consectetur adipisicing elit.'
  },
  {
    img: A5,
    review: "Give All Related Required Documents & Certificate Of Formats For Your Enrolled Programs"
  },
  {
    img: A6,
    review: "Lorem ipsum dolor sit amet, quasi. Natus, deserunt? consectetur adipisicing elit."
  },
  {
    img: A8,
    review: "Give All Related Required Documents & Certificate Of Formats For Your Enrolled Programs"
  }
];


export default reviewers;
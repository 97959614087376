// UserProfileMobile.js

import React, { useState, useEffect } from "react";
import { Box, Avatar, Typography, Button, Paper } from "@mui/material";
import A1 from "../../assets/A1.png";
import EditIcon from '@mui/icons-material/Edit';
import NotificationsMobile from "./NotificationsMobile";
import UserContentMobile from "./UserContentMobile";
import EditProfileMobile from "./EditProfileMobile";
import SubDetailsMobile from "./SubDetailsMobile";
import ButtonsMobile from "./ButtonsMobile";

function UserProfileMobile() {
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState(false);
  // const [userData, setUserData] = useState("");

  
  useEffect(() => {
    const userdataString = sessionStorage.getItem("user");
    const userdata = JSON.parse(userdataString);
    setUserData(userdata);
    console.log(userData);
    
  }, []);
  
  const handleClickOpen = () => {
    setOpen(true);
    // setUserData(userData.fname)
  }
  const handleClose = () => setOpen(false);


  return (
    <Box sx={{ mt: '5rem', py: 2 }}>
      {/* User Info Section */}
      <Paper sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 2, borderRadius: 3, boxShadow: 3 }}>
        <Avatar src={A1} sx={{ width: 100, height: 100, mb: 2 }} />
        <Typography variant="h5" sx={{ fontWeight: "600" }}>
          {userData?.fname} {userData?.lastname}
        </Typography>
        <Button 
          // startIcon={<EditIcon />} 
          variant="outlined" 
          sx={{ mt: 2, px: 4, color: "#008080" }} 
          onClick={handleClickOpen}>
          See Profile
        </Button>
      </Paper>

      {/* Subscription Deatils */}
      {userData && <SubDetailsMobile userData={userData} />}

      {/* Notifications Section */}
      <NotificationsMobile />
      
      {/* Buttons Section */}
      <ButtonsMobile/>

      {/* User Content Section */}
      <UserContentMobile />

      {/* Edit Profile Modal */}
      <EditProfileMobile open={open} handleClose={handleClose} userData={userData} />
    </Box>
  );
}

export default UserProfileMobile;

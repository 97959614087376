// UserContentMobile.js

import React from "react";
import { Box, Paper, Typography, Divider } from "@mui/material";

function UserContentMobile() {
  return (
    <Paper sx={{ marginTop: 2, padding: 2, borderRadius: 3 }}>
      <Typography variant="h6" sx={{ color: "#008080", marginBottom: 2 }}>
        User Content
      </Typography>
      <Divider />
      <Box sx={{ marginTop: 2, minHeight: "20vh" }}>
        {/* Placeholder for user content */}
        <Typography variant="body2" sx={{ color: "gray" }}>
          No content available
        </Typography>
      </Box>
    </Paper>
  );
}

export default UserContentMobile;

import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Button } from "@mui/material";
import UpdateSubPlan from "../dialogBoxses_/updateSubPlan_/UpdateSubPlan";
import { fetchPlansData } from "../../data_/SubPlanData";

const AdminPanel = () => {
  const [plans, setPlans] = useState([]);
  const [openPlan, setOpenPlan] = useState(false);
  const [openExam, setOpenExam] = useState(false);

  useEffect(() => {
    // Fetch the plans data on component mount
    const loadPlans = async () => {
      const plansData = await fetchPlansData();
      setPlans(plansData);
    };

    loadPlans();
  }, []);

  const handleClickOpenPlan = () => {
    setOpenPlan(true);
  };

  const handleClickOpenExam = () => {
    setOpenExam(true);
  };

  return (
    <Box
      sx={{
        width: "25vw",
        height: "98.5%",
        m: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={8}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 2,
            p: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={handleClickOpenPlan}
            sx={{
              width: "40vh",
              bgcolor: "#008080",
              "&:hover": { bgcolor: "#006666" },
            }}
          >
            Update Subscription Plans
          </Button>
          <UpdateSubPlan setOpenPlan={setOpenPlan} openPlan={openPlan} />
        </Box>

        <Box
          sx={{
            width: "90%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h6" sx={{ color: "#006666" }}>
            Existing Plans:
          </Typography>

          <Box
            sx={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            {plans.map((plan, index) => (
              <Box
                key={index}
                sx={{
                  border: "1px solid #008080",
                  borderRadius: "5px",
                  width: "95%",
                  p: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ textAlign: "center" }}>
                  {plan.title}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AdminPanel;

import * as React from "react";
import Pic1 from "../../assets/EDUCATION1.png";
import Pic2 from "../../assets/EDUCATION2.png";
import Pic3 from "../../assets/EDUCATION3.png";
import Carousel from "react-material-ui-carousel";
import { StyledCarousel, StyledCarouselItem, StyledTextContainer, StyledTypography, StyledImage, StyledHeaderContainer } from "./HeaderStyles";

const images = [
  {
    text: "Start Your Journey relentlessly with Admission first",
    src: Pic1,
    alt: "Image 1",
  },
  {
    text: "We help to make Your DREAMS true",
    src: Pic2,
    alt: "Image 2",
  },
  {
    text: "Admission first - let make admissions easy",
    src: Pic3,
    alt: "Image 3",
  },
];

const ImageSlider = () => {
  return (
    <StyledCarousel navButtonsAlwaysInvisible
    indicatorIconButtonProps = {{
      style: {
        color: '#648888',
      }
    }}
      activeIndicatorIconButtonProps={{
        style: {
          color: "#006666", // Selected indicator color
        },
      }}
      sx={{ width: "100%", height: "100%" }}
    >
      
      {images.map((item, index) => (
        <StyledCarouselItem key={index}>
          <StyledTextContainer>
            <StyledTypography>{item.text}</StyledTypography>
            <StyledImage component="img" src={item.src} alt={item.alt} />
          </StyledTextContainer>
        </StyledCarouselItem>
      ))}
      
    </StyledCarousel>
  );
};

function Header() {
  return (
    <StyledHeaderContainer>
      <ImageSlider />
    </StyledHeaderContainer>
  );
}

export default Header;
import { useTheme, useMediaQuery } from "@mui/material";
import UserProfileDesktop from "./UserProfileDesktop";
import UserProfileMobile from "../../component/userProfileMobile/UserProfileMobile";


function UserProfile() {

  const theme = useTheme(); // Access the theme object
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(1000)); // Use theme breakpoints
  
  const screenVisible = isLargeScreen ?
                          [ <UserProfileDesktop/> ]
                        :
                          [ <UserProfileMobile/> ]
  
  return (

    <>
      {screenVisible}
    </>

  );
}

export default UserProfile;

import { Box, Paper, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";

export const StyledBox = styled(Box)(({ theme }) => ({
  width: "25vw",
  height: "119vh",
  margin: theme.spacing(1),
  borderRadius: "12px",
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: '2rem',
}));

export const ImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: '2rem',
}));

export const UserImage = styled(Box)(({ theme }) => ({
  width: "8vw",
  height: "16vh",
}));

export const UserNameTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
}));

export const InfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginX: theme.spacing(1),
  marginY: theme.spacing(4),
}));

export const InfoTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  color: "#006666",
  textAlign: "left",
  width: "85%",
}));

export const DetailBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
  margin: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

export const InfoRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "20vw",
  alignItems: "center",
}));

export const InfoLabel = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  fontWeight: "600",
  color: "grey",
}));

export const InfoValue = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  fontWeight: "600",
}));

export const EditButtonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(10),
}));

export const EditButton = styled(Button)(({ theme }) => ({
  color: "#006666",
  borderColor: "#008080",
}));
import React from "react";

import { styled } from "@mui/material/styles";
import { Box, Paper, Typography, Divider } from "@mui/material";


// Paper styling for the subscription details card, optimized for mobile
const SubscriptionPaper = styled(Paper)(({ theme }) => ({
//   width: "95%", // Slightly smaller width for mobile
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  borderRadius: "12px",
}));

// Title Typography, slightly smaller for mobile screens
const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1rem", // Smaller font size on mobile
  fontWeight: 600,
  color: "#006666",
  marginBottom: theme.spacing(1),

  [theme.breakpoints.up("sm")]: {
    fontSize: "1.2rem", // Larger font size for bigger screens
    marginBottom: theme.spacing(2),
  },
}));

// Divider styling, smaller margins for mobile
const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(2), // Reduced space on mobile

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(3),
  },
}));

// Subscription Info Box styling for mobile
const SubscriptionInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5), // Smaller gap on mobile

  [theme.breakpoints.up("sm")]: {
    gap: theme.spacing(2),
  },
}));

// Row for subscription detail (label and value), responsive adjustments
const SubscriptionDetailRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

// Label Typography styling for mobile
const LabelTypography = styled(Typography)({
  fontWeight: 500,
  color: "#333",
});

// Value Typography styling for mobile
const ValueTypography = styled(Typography)({
  fontWeight: 600,
});


function SubDetailsMobile({ userData }) {
  return (
      <SubscriptionPaper >
        {/* Title */}
        <TitleTypography variant="h6">
          Subscription Details
        </TitleTypography>
        <StyledDivider />

        {/* Subscription Information */}
        <SubscriptionInfoBox>
          <SubscriptionDetailRow>
            <LabelTypography>Subscription Name:</LabelTypography>
            <ValueTypography>
              {userData["subscription"] || "No subscription"}
            </ValueTypography>
          </SubscriptionDetailRow>
          <SubscriptionDetailRow>
            <LabelTypography>Status:</LabelTypography>
            <ValueTypography>
              {userData["subscription_status"] || "Inactive"}
            </ValueTypography>
          </SubscriptionDetailRow>
        </SubscriptionInfoBox>
      </SubscriptionPaper>
  );
}

export default SubDetailsMobile;
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";

// Styled components
export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  marginBottom: '2rem',
}));

export const StyledCarouselItem = styled(Box)(({ theme }) => ({
  width: "100vw",
  py: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    py: 1.5,
  },
  [theme.breakpoints.between('850', '1000')]: {
    py: 1,
  },
  [theme.breakpoints.between('600', '850')]: {
    py: 0.5,
  },
  [theme.breakpoints.between('400', '600')]: {
    py: 0.2,
  },
  [theme.breakpoints.down('400')]: {
    py: 0,
  },
}));

export const StyledTextContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.between('850', '1000')]: {
    padding: theme.spacing(1.5),
  },
  [theme.breakpoints.between('600', '850')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.between('400', '600')]: {
    padding: theme.spacing(0.5),
  },
  [theme.breakpoints.down('400')]: {
    padding: theme.spacing(0),
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  my: 1,
  height: "10vh",
  display: "flex",
  alignItems: "center",
  textAlign: "center", // Center the text
  padding: "0 0rem", // Add padding for better mobile viewing

  // Text Shadow for depth and emphasis
  textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',

  // Gradient Text Effect
  backgroundImage: 'linear-gradient(45deg, #ff6b81, #ffc93c)',
  WebkitBackgroundClip: 'text', // For Safari
  color: 'transparent', // To show the gradient effect

  // Font Styling: Use a bold font with bigger size for impact
  fontFamily: "'Roboto', sans-serif",
  fontWeight: 'bold',
  fontSize: "1.5rem",
  
  // Padding and margin for better readability
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),

  // Add some padding around the text to create space
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  
  // Animation for text appearing with a fade effect
  animation: 'fadeIn 1s ease-in-out',
}));

// CSS keyframes for fade-in effect
const GlobalStyles = () => {
  return (
    <style>
      {`
        @keyframes fadeIn {
          0% { opacity: 0; }
          100% { opacity: 1; }
        }
      `}
    </style>
  );
};
export const StyledImage = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  maxHeight: "70vh",
  borderRadius: 2,

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    width: "85vw",
    maxHeight: "65vh",
  },
  [theme.breakpoints.between('850', '1000')]: {
    width: "90vw",
    maxHeight: "60vh",
  },
  [theme.breakpoints.between('600', '850')]: {
    width: "95vw",
    maxHeight: "55vh",
  },
  [theme.breakpoints.between('600', '700')]: {
    width: "97vw",
    maxHeight: "50vh",
  },
  [theme.breakpoints.between('500', '600')]: {
    width: "100vw",
    maxHeight: "45vh",
  },
  [theme.breakpoints.down('500')]: {
    width: "90vw",
    maxHeight: "40vh",
  },
}));

export const StyledHeaderContainer = styled(Box)(({ theme }) => ({
  width: "90%",
  height: "90vh",
  marginTop: theme.spacing(10),
  display: "flex",

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    height: "85vh",
    marginTop: theme.spacing(8),
  },
  [theme.breakpoints.between('850', '1000')]: {
    height: "80vh",
    marginTop: theme.spacing(6),
  },
  [theme.breakpoints.between('600', '850')]: {
    height: "75vh",
    marginTop: theme.spacing(5),
  },
  [theme.breakpoints.between('600', '700')]: {
    height: "70vh",
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.between('500', '600')]: {
    height: "65vh",
    marginTop: theme.spacing(3),
  },
  [theme.breakpoints.down('500')]: {
    height: "60vh",
    marginTop: theme.spacing(8),
  },
}));
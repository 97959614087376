import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, TextField } from "@mui/material";
import A1 from '../../../assets/A1.png'
import { AddAPhotoOutlined } from '@mui/icons-material';


const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: '#000',  // Text color
  },
  '& .MuiInputLabel-root': {
    color: '#006666',  // Label color
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: '#008080',  // Underline color when not focused
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#008080',  // Underline color when focused
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: '#008080',  // Underline color on hover
  },
});


function EditProfile({ open, handleClose }) {
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: "40vw",
            height: '80vh'
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: '#006666' }} >
          Edit Profile
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
          <Box>
            <Box component="img" src={A1} sx={{ width: '8vw', height: '16vh' }} />
            <AddAPhotoOutlined sx={{ position: 'absolute', top: '10rem', right: '16rem', fontSize: '1rem', color: 'antiquewhite', bgcolor: 'black', borderRadius: '50%', p: 0.5 }} />
          </Box>
          <StyledTextField label="NAME" variant="standard" sx={{ width: '45%', mb: '3rem' }} />
          <Box sx={{ width: '90%', display: 'grid', gridTemplateColumns: 'auto auto', gap: 3,}} >
            <StyledTextField label="AGE" variant="standard" />
            <StyledTextField label="EDUCATION" variant="standard" />
            <StyledTextField label="PHONE" variant="standard" />
            <StyledTextField label="EMAIL" variant="standard" />
            <StyledTextField label="CITY" variant="standard" />
            <StyledTextField label="STATE" variant="standard" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: '#008080' }} >
            Cancel
          </Button>
          <Button autoFocus onClick={handleClose} sx={{ color: '#008080' }} >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditProfile;

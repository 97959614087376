import React from "react";
import { Typography, TextField, Box } from "@mui/material";
import { LocationOn, Phone, Email } from "@mui/icons-material";
import {
  ContactContainer,
  InnerBox,
  HeaderBox,
  StyledContentBox,
  IconTextBox,
  ContactDetail,
  IconWrapper,
  FormBox,
  SendButton,
} from "./ContactStyles";

function Contact() {
  return (
    <ContactContainer>
      <InnerBox>
        <HeaderBox>
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", color: "#006666" }}
          >
            Contact Us
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </Typography>
        </HeaderBox>

        <StyledContentBox>
          {/* Left Side - Contact Information */}
          <IconTextBox>
            <ContactDetail>
              <IconWrapper>
                <LocationOn />
              </IconWrapper>
              <Box sx={{ ml: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Address
                </Typography>
                <Typography variant="body1">
                  Admission First, Sutmill Road, Beside Era Apartment,
                  <br/>Latur-413512
                </Typography>
              </Box>
            </ContactDetail>

            <ContactDetail>
              <IconWrapper>
                <Phone />
              </IconWrapper>
              <Box sx={{ ml: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Phone
                </Typography>
                <Typography variant="body1">+91 2382796457
                  <br/>+91 8830315658</Typography>
              </Box>
            </ContactDetail>

            <ContactDetail>
              <IconWrapper>
                <Email />
              </IconWrapper>
              <Box sx={{ ml: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Email
                </Typography>
                <Typography variant="body1">admissionfirst7@gmail.com</Typography>
              </Box>
            </ContactDetail>
          </IconTextBox>

          {/* Right Side - Contact Form */}
          <FormBox>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", mb: 2, color: "#008080" }}
            >
              Send Message
            </Typography>
            <TextField
              fullWidth
              label="Full Name"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Type your Message..."
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
            />
            <SendButton variant="contained">Send</SendButton>
          </FormBox>
        </StyledContentBox>
      </InnerBox>
    </ContactContainer>
  );
}

export default Contact;

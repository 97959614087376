import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Button,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import A1 from "../../assets/A1.png";

function EditProfileMobile({ open, handleClose }) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [education, setEducation] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    // Optionally, handle save logic here (e.g., send data to the server)
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  }

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle sx={{ color: "#008080", position: "relative" }}>
        User Profile
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <Divider/>
      
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 5 }}
      >
        <Box>
          <Box
            component="img"
            src={A1}
            sx={{ width: "100px", height: "100px", mb: 2, borderRadius: "50%" }}
          />
        </Box>

        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          variant="standard"
          sx={{ mb: 2 }}
          disabled={!isEditing} // Disable if not in edit mode
        />
        <TextField
          label="Age"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          fullWidth
          variant="standard"
          sx={{ mb: 2 }}
          disabled={!isEditing} // Disable if not in edit mode
        />
        <TextField
          label="Education"
          value={education}
          onChange={(e) => setEducation(e.target.value)}
          fullWidth
          variant="standard"
          sx={{ mb: 2 }}
          disabled={!isEditing} // Disable if not in edit mode
        />
        <TextField
          label="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          variant="standard"
          sx={{ mb: 2 }}
          disabled={!isEditing} // Disable if not in edit mode
        />
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          variant="standard"
          sx={{ mb: 2 }}
          disabled={!isEditing} // Disable if not in edit mode
        />
        <TextField
          label="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          fullWidth
          variant="standard"
          sx={{ mb: 2 }}
          disabled={!isEditing} // Disable if not in edit mode
        />
        <TextField
          label="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
          fullWidth
          variant="standard"
          sx={{ mb: 2 }}
          disabled={!isEditing} // Disable if not in edit mode
        />

        {!isEditing ? (
          <Button variant="contained" onClick={handleEditClick} sx={{ color: "white", mt: 3 }}>
            Edit Profile
          </Button>
        ) : (
          <Box sx={{ display: 'flex', gap: 2, mt: 3 }} >
            <Button variant="contained" onClick={handleCancel} sx={{ color: "white" }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSaveClick} sx={{ color: "white" }}>
              Save
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default EditProfileMobile;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20vw',
  height: '35vh',
  borderRadius: '5px',
  [theme.breakpoints.down(1000)]: {
    width: '30vw',
  },
  [theme.breakpoints.down(600)]: {
    width: '40vw',
    height: '25vh',
  },
}));

export const StyledImage = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '70%',
  borderRadius: theme.spacing(1, 1, 0, 0),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '17%',
  backgroundColor: 'grey',
  color: 'antiquewhite',
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '13%',
  // backgroundColor: 'peru',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const VisitButton = styled(Button)(({ theme }) => ({
  width: '49.8%',
  borderRadius: 0,
  backgroundColor: '#138080',
  fontSize: '0.9rem',
  '&:hover': {
    backgroundColor: '#006666',
  },
  [theme.breakpoints.down(600)]: {
    fontSize: '0.7rem',
  },
}));

export const DownloadButton = styled(Button)(({ theme }) => ({
  width: '49.8%',
  borderRadius: 0,
  fontSize: '0.9rem',
  backgroundColor: '#138080',
  '&:hover': {
    backgroundColor: '#006666',
  },
  [theme.breakpoints.down(600)]: {
    fontSize: '0.7rem',
  },
}));
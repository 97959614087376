import React, { useEffect, useState } from "react";
import PlanCard from "../../component/subPlanCard_/PlanCard";
import { Typography, Grid, Box } from "@mui/material";
import { ResponsiveBox, PlanContainerBox, HeadContainer, HeaderImage, } from "./CounsellingStyles";
import { fetchPlansData } from "../../component/data_/SubPlanData"; // Import the fetch function
import HeaderImg from '../../assets/subscribe2.jpg'


function Counselling() {
  const [plans, setPlans] = useState([]); // State to hold the fetched plans

  // Fetch the plans data when the component mounts
  useEffect(() => {
    async function getPlans() {
      try {
        const fetchedPlans = await fetchPlansData(); // Fetch data from API
        // console.log('Fetched Plans:', CardData); // Log the fetched plans to ensure they are correct
        setPlans(fetchedPlans); // Set the fetched plans to the state
        console.log(plans);
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    }

    getPlans(); // Call the function to fetch plans
  }, []); // Empty dependency array to ensure it only runs once on mount

  return (
    <ResponsiveBox>
      <HeadContainer>
        <HeaderImage component="img" src={HeaderImg} />
        <Typography variant="h2" align="center" sx={{ mt: 5, fontSize: { lg: '4rem', sm: '3rem', xs: '2rem' }, color: '#006666', fontWeight: '700' }}>
          Subscription Plans
        </Typography>
      </HeadContainer>
      <PlanContainerBox>
        {plans.length > 0 ? (
          plans.map((plan, index) => (
            <Box key={index} sx={{ mb: 10 }}>
              <PlanCard {...plan} />
            </Box>
          ))
        ) : (
          <Typography variant="h6" align="center">
            No plans available at the moment.
          </Typography>
        )}
      </PlanContainerBox>
    </ResponsiveBox>
  );
}

export default Counselling;

import { Box, Card, CardHeader, IconButton, Typography } from "@mui/material";
import React from "react";

function ServicesCard({ icon, service, info }) {
  return (
    <>
      <Card
        sx={{
          bgcolor: "#f0f0f0",
          color: "#000",
          width: { xs: "75%", md: "100%" },
          height: { xs: "10rem", md: "16rem" },
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: 'space-evenly',
          p: 1,
        }}
      >
        <Box sx={{ height: '30%' }} >
          <IconButton
            disableRipple={true}
            sx={{
              mt: { xs: 1, md: 2 },
              // fontSize: '5rem',
              bgcolor: '#008080',
            }}
          >
            {icon}
          </IconButton>
        </Box>

        <CardHeader
          title={service}
          titleTypographyProps={{
            height: '30%',
            fontSize: { xs: "0.6rem", md: "1.2rem" },
            fontWeight: { xs: "900", md: "600" },
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "#006666",
            height: '30%',
            fontSize: { xs: "0.5rem", md: "0.8rem" },
            fontWeight: "600",
          }}
        >
          {info}
        </Typography>
      </Card>
    </>
  );
}

export default ServicesCard;

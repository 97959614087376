import React, { useEffect, useState } from "react";
import Header from "../../component/header_/Header";
import Ratings from "../../component/ratings_/Ratings";
import Reviews from "../../component/reviews_/Reviews";
import { Grid } from "@mui/material";
import PlanSlider from "../../component/planSlider_/PlanSlider";
import ServicesSection from "../../component/servicesSection_/ServicesSection";

function Home() {
  const [loggedUser, setLoggedUser] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userId, setUserId] = useState();

  useEffect(() => {
    // Retrieve the stored user information from sessionStorage
    const storedUser = sessionStorage.getItem("user");

    if (storedUser) {
      // Parse the string to an object
      const user = JSON.parse(storedUser);
      
      // Set user info in the state
      setLoggedUser(user.fname);
      setUserRole(user.role);
      setUserId(user.id);

      // Check if the page has been refreshed already
      const hasRefreshed = sessionStorage.getItem('hasRefreshed');

      // If user is logged in and page hasn't been refreshed, refresh once
      if (!hasRefreshed) {
        sessionStorage.setItem('hasRefreshed', 'true'); // Mark refresh as done
        setTimeout(() => {
          window.location.reload();  // Refresh the page once
        }, 1000);  // 1000 milliseconds = 1 second
      }
    }

  }, []);

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Header loggedUser={loggedUser} userRole={userRole} userId={userId} />
        <Ratings />
        <PlanSlider />
        <ServicesSection />
        <Reviews />
      </Grid>
    </>
  );
}

export default Home;

import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";

function UserContent() {
  return (
    <Box
      sx={{
        width: "70vw",
        height: "auto",
        m: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={8}
        sx={{ width: "100%", height: "100%", borderRadius: "12px" }}
      >
        <Box sx={{ m: 1 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "1rem", fontWeight: "600", color: "#006666" }}
          >
            User Content :
          </Typography>
        </Box>
        <Divider />

        <Box sx={{ m: 5, minHeight: "30vh" }}></Box>
      </Paper>
    </Box>
  );
}

export default UserContent;

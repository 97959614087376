import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CardContent,
  Typography,
  CardActions,
  Box,
  Divider,
} from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  StyledCard,
  StyledChip,
  StyeledBox,
  StyledTitle,
  StyledHeading,
  StyledCourses,
  ContentTypography,
  PriceTypography,
  TextTypography,
  FeatureList,
  StyledButton,
} from "./PlanCardStyles";

// Subscription Plan Card Component
function PlanCard({ type, title, heading, course_offered, price, validity }) {
  const navigate = useNavigate();

  
  const gotToNewPage = () => {
    // Pass the title to the payment page using 'state'
    navigate("/payment", {state: { 
      type,
      title, 
      heading, 
      course_offered, 
      price,
      validity, 
      features 
    }  });
    window.scrollTo(0, 0);
  };

  const features = [
    "Notification/Events",
    "Require Documents",
    "Admission Process",
    "Reservation Quota",
    "Previous Year Cut Off",
    "Round Alerts",
  ]

  return (
    <StyledCard variant="outlined">
      <StyledChip size="lg" variant="outlined">
        <Typography sx={{ fontSize: '1rem' }} >{type}</Typography>
      </StyledChip>
      <StyeledBox>
        <StyledTitle title={title} />
        <StyledHeading>{heading}</StyledHeading>
        <StyledCourses>{course_offered}</StyledCourses>
      </StyeledBox>

      <CardContent sx={{ mt: -1, }} >
        <Divider sx={{ border: '1px solid grey' }} />

        <ContentTypography>
          <PriceTypography variant='h4' >₹{price}</PriceTypography>
          <TextTypography variant='body1' >per month</TextTypography>
        </ContentTypography>
        
        <Divider sx={{ border: '1px solid grey' }} />

        <FeatureList>
          <ul>
            {features.map((feature, index) => (
              <li key={index}>
                <VerifiedIcon sx={{ color: 'green', fontSize: 'large', marginRight: 3 }} />
                <Typography variant="body2">{feature}</Typography>
              </li>
            ))}
          </ul>
        </FeatureList>
      </CardContent>

      <CardActions >
        <StyledButton variant="contained" onClick={() => gotToNewPage()} >
          Enroll Now
        </StyledButton>
      </CardActions>
    </StyledCard>
  );
}

export default PlanCard;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './component/Layout';
import './index.css';

import Home from './pages/home_/Home';
import About from './pages/aboutUs_/About';
import Admin from './pages/admin_/Admin';
import Contact from './pages/contactUs_/Contact';
import Counselling from './pages/counselling_/Counselling';
import Error from './pages/Error';
import UserProfile from './pages/userProfile_/UserProfile';
import SignIn from './pages/signIn_/SignIn';
import SignUp from './pages/registration_/SignUp';
import Universities from './pages/universities_/Universities';
import EntranceExam from './pages/entranceExam_/EntranceExam';
import PaymentPage from './pages/payment_/PaymentPage';
import ProtectedRoute from './component/ProtectedRoute';
import ForgotPassword from './pages/forgotPassword_/ForgotPassword';
import ResetPassword from './pages/resetPassword_/ResetPassword';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Home /> },
      { path: 'about_us', element: <About /> },
      { path: 'contact_us', element: <Contact /> },
      { path: 'universities', element: <Universities /> },
      { path: 'entrance_exam', element: <EntranceExam /> },
      { path: 'counselling', element: <Counselling /> },
      // Protected Routes
      // {
      //   element: <ProtectedRoute requiredRole="premium" />,
      //   children: [
          { path: 'profile/:id', element: <UserProfile /> }, // Student only
          { path: 'payment', element: <PaymentPage /> },
      //   ],
      // },
      // {
      //   element: <ProtectedRoute requiredRole="admin" />,
      //   children: [
          { path: 'admin/:id', element: <Admin /> }, // Admin only
      //   ],
      // },
      { path: 'signin', element: <SignIn /> },
      { path: 'signup', element: <SignUp /> },
      { path: 'forgot_password', element: <ForgotPassword /> },
      { path: 'reset-password/:token', element: <ResetPassword /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
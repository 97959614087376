// UniversitiesStyles.js
import { styled } from "@mui/material/styles";
import { Box, Typography, Paper, Divider, Button } from "@mui/material";

export const RootContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '4.6rem',
}));

export const HeaderContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: 'white',
});

export const HeaderImage = styled(Box)(({ theme }) => ({
  width: '98vw',
  height: '80vh',
  objectFit: 'cover',
  backgroundColor: '#000',
  filter: 'brightness(0.4)',
  [theme.breakpoints.down('sm')]: {
    height: '25vh',
  }
}));

export const TextOverlay = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
});

export const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export const InnerContainer = styled(Box)(({ theme }) => ({
  // marginTop: "1rem",
}));

export const SearchContainer = styled(Box)(({ theme }) => ({
  // marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const DropdownContainer = styled(Box)(({ theme }) => ({
  width: "80%",
  marginTop: "5rem",
}));

export const UniversityName = styled(Typography)(({ theme }) => ({
  marginTop: "4rem",
  marginBottom: "2rem",
  fontWeight: "bold",
  color: "#006666",
  [theme.breakpoints.down(500)]: {
    textAlign: 'center',
  }
}));

export const CoursesWrapper = styled(Box)(({ theme }) => ({
  width: "80vw",
  height: "70vh",
  marginTop: "2rem",
  marginBottom: "3rem",
  [theme.breakpoints.down(1000)]: {
    height: '95vh'
  },
  [theme.breakpoints.down(500)]: {
    width: '95vw',
    height: "160vh",
  },
}));

export const PaperContainer = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  backgroundColor: "#f0f0f0",
}));

export const CoursesInnerBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: 'row',
  justifyContent: "space-evenly",
  alignItems: "center",
  [theme.breakpoints.down(1000)]: {
    flexDirection: 'column',
  height: "100%",
  }
}));

export const ColumnWrapper = styled(Box)(({ theme }) => ({
  width: "49%",
  height: "95%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  [theme.breakpoints.down(1000)]: {
    width: '90%',
    height: "49%",
  }
}));

export const ColumnTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
}));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  borderBottom: "1px solid #008080",
  width: "14vw",
  [theme.breakpoints.down(1000)]: {
    width: '25vw',
  }
}));

export const DividerVerticalStyled = styled(Divider)(({ theme }) => ({
  height: "95%",
  border: "1px solid #008080",
  [theme.breakpoints.down(1000)]: {
    width: '90%',
    height: 0,
  }
}));

export const CoursesGrid = styled(Box)(({ theme }) => ({
  width: "95%",
  height: "80%",
  marginLeft: theme.spacing(1),
  display: "grid",
  gridTemplateColumns: "auto auto auto",
  gap: theme.spacing(3),
  [theme.breakpoints.down(500)]: {
    gridTemplateColumns: "auto auto",
  },
}));

export const CourseItem = styled(Box)(({ theme }) => ({
  width: "10vw",
  textAlign: "left",
  marginTop: theme.spacing(1),
}));

export const VisitWebsiteButton = styled(Button)(({ theme }) => ({
  color: "#000",
  fontWeight: 700,
}));

export const BottomDivider = styled(Divider)(({ theme }) => ({
  border: "1px solid #008080",
  marginBottom: "1.5rem",
  width: "10vw",
}));
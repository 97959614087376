import img from '../../assets/Exam.gif';

const details = [
    {
        level: 'National',
        examName: 'NEET UG',
        cardImg: img,
        website: 'https://exams.nta.ac.in/NEET/',
        exam_document: 'Docs/NEET UG .pdf',
    },
    {
        level: 'National',
        examName: 'JEE MAIN',
        cardImg: img,
        website: 'https://jeemain.nta.ac.in/',
        exam_document: 'Docs/JEE MAIN.pdf',
    },
    {
        level: 'National',
        examName: 'COMEDK UG',
        cardImg: img,
        website: 'https://www.comedk.org/index',
        exam_document: 'Docs/COMEDK UG.pdf',
    },
    {
        level: 'State',
        examName: 'MHT-CET (MH-STATE)',
        cardImg: img,
        website: 'https://cetcell.mahacet.org/',
        exam_document: 'Docs/MHT-CET-2024.pdf',
    },
    {
        level: 'State',
        examName: 'MCA CET (MH-STATE)',
        cardImg: img,
        website: 'https://cetcell.mahacet.org/',
        exam_document: 'Docs/MCA-CET-2024-25.pdf',
    },
    {
        level: 'State',
        examName: 'MBA CET (MH-STATE)',
        cardImg: img,
        website: 'https://cetcell.mahacet.org/',
        exam_document: 'Docs/MBA-CET-2024_Final.pdf',
    },
    {
        level: 'State',
        examName: 'BCA-BBA CET (MH-STATE)',
        cardImg: img,
        website: 'https://cetcell.mahacet.org/',
        exam_document: 'Docs/BCA-BBA-BMS-BBM-CET-2024-25-Information-Brochure-final.pdf',
    },
    {
        level: 'State',
        examName: 'LLB 5 YEARS CET (MH-STATE)',
        cardImg: img,
        website: 'https://cetcell.mahacet.org/',
        exam_document: 'Docs/LLB-5-YEARS-CET-2024-IB.pdf',
    },
    {
        level: 'State',
        examName: 'LLB 3 YEARS CET (MH-STATE)',
        cardImg: img,
        website: 'https://cetcell.mahacet.org/',
        exam_document: 'Docs/LLB-3-YEARS-CET-2024-IB.pdf',
    },
    {
        level: 'State',
        examName: 'NURSING CET (MH-STATE)',
        cardImg: img,
        website: 'https://cetcell.mahacet.org/',
        exam_document: 'Docs/NURSING CET.pdf',
    },
    {
        level: 'National',
        examName: 'JEE ADVANCED',
        cardImg: img,
        website: 'https://jeeadv.ac.in/',
        exam_document: 'Docs/JEE ADVANCED.pdf',
    },
    {
        level: 'National',
        examName: 'CUET UG',
        cardImg: img,
        website: 'https://exams.nta.ac.in/CUET-UG/',
        exam_document: 'Docs/CUET UG.pdf',
    },
    {
        level: 'National',
        examName: 'CUET PG',
        cardImg: img,
        website: 'https://pgcuet.samarth.ac.in/',
        exam_document: 'Docs/CUET PG.pdf',
    },
    {
        level: 'National',
        examName: 'BITSAT',
        cardImg: img,
        website: 'https://www.bitsadmission.com/index.aspx',
        exam_document: 'Docs/BITSAT-2024_brochure.pdf',
    },
    {
        level: 'National',
        examName: 'VITEEE UG',
        cardImg: img,
        website: 'https://viteee.vit.ac.in/',
        exam_document: 'Docs/VITEEE-2024-information-brochure.pdf',
    },
];

export default details
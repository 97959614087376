import React from 'react'

function Error() {
  return (
    <h2>
      ERROR : Page Not Found!
    </h2>
  )
}

export default Error

import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";

export const CarouselContainer = styled(Box)(({ theme }) => ({
  border: '1px solid #CCC',
  paddingBottom: '2rem',
  position: 'relative',
  width: '100%',
  height: '30rem',
  perspective: '1000px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
//   backgroundColor: 'red',

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    height: '28rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    height: '26rem',
    perspective: '600px',
  },
  [theme.breakpoints.between('600', '850')]: {
    height: '24rem',
    perspective: '400px',
  },
  [theme.breakpoints.between('600', '700')]: {
    height: '22rem',
  },
  [theme.breakpoints.between('400', '600')]: {
    height: '20rem',
  },
  [theme.breakpoints.down('400')]: {
    height: '18rem',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0',
    paddingBottom: '0',
  },
}));

export const StyledTitleBox = styled(Box)(({ theme }) => ({
  marginTop: '2rem',
  marginBottom: '0',
  color: '#006666',

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    marginTop: '1.8rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    marginTop: '1.5rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    marginTop: '1.2rem',
  },
  [theme.breakpoints.between('600', '700')]: {
    marginTop: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
  },
}));

export const CarouselBox = styled(Box)(({ selectedIndex, translateZValue, theme }) => ({
  width: '100%',
  height: '340px',
  transform: `translateZ(-${translateZValue}) rotateY(${(selectedIndex / 9) * -360}deg)`,
  transformStyle: 'preserve-3d',
  transition: 'transform 1s',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  // Responsive height adjustment
  [theme.breakpoints.between('1000', '1200')]: {
    height: '320px',
  },
  [theme.breakpoints.between('850', '1000')]: {
    height: '300px',
  },
  [theme.breakpoints.between('600', '850')]: {
    height: '280px',
  },
  [theme.breakpoints.down('600')]: {
    height: '260px',
  },
  [theme.breakpoints.down('400')]: {
    marginBottom: '1rem'
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  width: '20rem',
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',

  // Responsive width adjustment
  [theme.breakpoints.between('1000', '1200')]: {
    width: '18rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    width: '16rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    width: '14rem',
  },
  [theme.breakpoints.between('600', '700')]: {
    width: '12rem',
  },
  [theme.breakpoints.between('400', '600')]: {
    width: '10rem',
  },
  [theme.breakpoints.down('400')]: {
    width: '8rem',
  },
  [theme.breakpoints.down('sm')]: {
    width: '10rem',
  },
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(5),

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    marginTop: theme.spacing(4.5),
  },
  [theme.breakpoints.between('850', '1000')]: {
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.between('600', '850')]: {
    marginTop: theme.spacing(3.5),
  },
  [theme.breakpoints.down('600')]: {
    marginTop: theme.spacing(0),
  },
}));

export const RotateButton = styled(Button)(({ rotate, theme }) => ({
  transform: rotate ? 'rotate(180deg)' : 'none',
  marginRight: rotate ? '8px' : '0',
  backgroundColor: '#008080',

  '&:hover': {
    backgroundColor: '#006666',
  },

  // Responsive adjustments for button size
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: '0.95rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    fontSize: '0.85rem',
  },
  [theme.breakpoints.down('600')]: {
    fontSize: '0.8rem',
  },
}));
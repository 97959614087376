import { styled } from '@mui/system';
import { Box, Typography, Rating, Divider } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: theme.spacing(5),
  
  [theme.breakpoints.between('1000', '1200')]: {
    marginTop: theme.spacing(4),  // Adjust spacing for 1000-1200 screens
  },
  [theme.breakpoints.between('850', '1000')]: {
    marginTop: theme.spacing(-35),  // Adjust spacing for 850-1000 screens
  },
  [theme.breakpoints.between('600', '850')]: {
    marginTop: theme.spacing(-12),  // Adjust spacing for 600-850 screens
  },
  [theme.breakpoints.between('400', '600')]: {
    marginTop: theme.spacing(-5), // Adjust spacing for 400-600 screens
  },
  [theme.breakpoints.down('400')]: {
    marginTop: theme.spacing(-18), // Adjust spacing for <400 screens
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '90vw',
  height: '0.2rem',
  
  [theme.breakpoints.between('1000', '1200')]: {
    width: '85vw',
  },
  [theme.breakpoints.between('850', '1000')]: {
    width: '80vw',
  },
  [theme.breakpoints.between('600', '850')]: {
    width: '75vw',
  },
  [theme.breakpoints.between('400', '600')]: {
    width: '70vw',
  },
  [theme.breakpoints.down('400')]: {
    width: '65vw',
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  marginTop: -20,
  
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.between('400', '600')]: {
    fontSize: '1.1rem',
  },
  [theme.breakpoints.down('400')]: {
    fontSize: '1rem',
  },
}));

export const StyledRatingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: 0,
});

export const StyledRating = styled(Rating)(({ theme }) => ({
  fontSize: '3rem',
  color: '#19ff15',
  top: -5,
  
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    fontSize: '2.2rem',
  },
  [theme.breakpoints.between('400', '600')]: {
    fontSize: '2rem',
    top: -8,
  },
  [theme.breakpoints.down('400')]: {
    fontSize: '1.8rem',
    top: -10,
  },
}));
import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const DropdownSearch = ({ data, searchTerm, handleSearch, handleUniversitySelect }) => {

  
  return (
    <div>
      <Autocomplete
        options={data.map((university) => university.name)}
        getOptionLabel={(option) => option}
        inputValue={searchTerm}
        onInputChange={handleSearch}
        onChange={handleUniversitySelect}
        renderInput={(params) => (
          <TextField {...params} label="Search Universities" variant="outlined" fullWidth />
        )}
      />
    </div>
  );
};


export default DropdownSearch;
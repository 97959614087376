const plans = [
  {
    planType: "Premium Plan",
    title: "THROUGH NEET UG MH-STATE",
    heading: "Admission to Health Science Courses",
    courses: "MBBS-BDS-BAMS-BHMS",
    price: 999,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Standard Plan",
    title: "THROUGH NEET UG All India Quota-AIIMS-JIPMER-BHU-AFMC-DEEMED",
    heading: "Admission to Health Science Courses",
    courses: "MBBS-BDS",
    price: 499,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Standard Plan",
    title: "THROUGH NEET UG All India Quota-DEEMED",
    heading: "Admission to Health Science Courses",
    courses: "BAMS-BHMS-BUMS-BSMS",
    price: 499,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Premium Plan",
    title: "THROUGH NEET UG Karnataka State(Other State Candidiate)",
    heading: "Admission to Health Science Courses",
    courses: "MBBS-BDS-BAMS",
    price: 999,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Premium Plan",
    title: "THROUGH NEET UG Telangana State(Other State Candidiate)",
    heading: "Admission to Health Science Courses",
    courses: "MBBS",
    price: 999,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Premium Plan",
    title: "THROUGH NEET UG Andhra Pradesh State(Other State Candidiate)",
    heading: "Admission to Health Science Courses",
    courses: "MBBS",
    price: 999,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Premium Plan",
    title: "THROUGH NEET UG Andhra Pradesh State(Other State Candidiate)",
    heading: "Admission to Health Science Courses",
    courses: "BAMS",
    price: 999,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Standard Plan",
    title: "THROUGH NEET UG MH-STATE",
    heading: "Admission to Health Science Courses",
    courses: "MBBS-BDS-BAMS-BHMS",
    price: 499,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Standard Plan",
    title: "THROUGH NEET UG MH-STATE",
    heading: "Admission to Health Science Courses",
    courses: "MBBS-BDS-BAMS-BHMS",
    price: 499,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Standard Plan",
    title: "THROUGH NEET UG MH-STATE",
    heading: "Admission to Health Science Courses",
    courses: "MBBS-BDS-BAMS-BHMS",
    price: 499,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Standard Plan",
    title: "THROUGH NEET UG MH-STATE",
    heading: "Admission to Health Science Courses",
    courses: "MBBS-BDS-BAMS-BHMS",
    price: 499,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
  {
    planType: "Standard Plan",
    title: "THROUGH NEET UG MH-STATE",
    heading: "Admission to Health Science Courses",
    courses: "MBBS-BDS-BAMS-BHMS",
    price: 499,
    features: [
      "Notification/Events",
      "Require Documents",
      "Admission Process",
      "Reservation Quota",
      "Previous Year Cut Off",
      "Round Alerts",
    ],
  },
];

export default plans;

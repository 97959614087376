import React, { useEffect } from "react";
import EntranceCard from "../../component/entranceCard_/EntranceCard";
import HeaderImg from '../../assets/exam__.png';
import details from "./examInfo";
import { Box, Typography } from "@mui/material";
import {
  MainBox,
  HeadContainer,
  HeaderImage,
  SectionContainer,
  SectionTitle,
  CardsContainer,
  CardWrapper,
} from './EntranceExamStyles';

function EntranceExam() {
  return (
    <MainBox>
      <HeadContainer>
        <HeaderImage component="img" src={HeaderImg} />
        <Typography variant="h2" align="center" sx={{ mt: 5, fontSize: { lg: '4rem', sm: '3rem', xs: '2rem' }, color: '#006666', fontWeight: '700' }}>
          Entrance Exam
        </Typography>
      </HeadContainer>
      <SectionContainer>
        <SectionTitle variant="h5">
          National Level Exams
        </SectionTitle>
        <CardsContainer>
          {details.filter(detail => detail.level === "National")
          .map((detail, index) => (
            <CardWrapper key={index}>
              <EntranceCard {...detail} />
            </CardWrapper>
          ))}
        </CardsContainer>
      </SectionContainer>
      <SectionContainer sx={{ mt: 0 }} >
        <SectionTitle variant="h5">
          State Level Exams
        </SectionTitle>
        <CardsContainer>
          {details.filter(detail => detail.level === "State")
          .map((detail, index) => (
            <CardWrapper key={index}>
              <EntranceCard {...detail} />
            </CardWrapper>
          ))}
        </CardsContainer>
      </SectionContainer>
    </MainBox>
  );
}

export default EntranceExam;
import React from "react";
import {
  SubDetailsContainer,
  SubscriptionPaper,
  TitleTypography,
  StyledDivider,
  SubscriptionInfoBox,
  SubscriptionDetailRow,
  LabelTypography,
  ValueTypography,
} from "./SubDetailsStyles"; // Import your styles

function SubDetails({ userData }) {
  return (
    <SubDetailsContainer>
      <SubscriptionPaper elevation={8}>
        {/* Title */}
        <TitleTypography variant="h6">
          Subscription Details
        </TitleTypography>
        <StyledDivider />

        {/* Subscription Information */}
        <SubscriptionInfoBox>
          <SubscriptionDetailRow>
            <LabelTypography>Subscription Name:</LabelTypography>
            <ValueTypography>
              {userData["subscription"] || "No subscription"}
            </ValueTypography>
          </SubscriptionDetailRow>
          <SubscriptionDetailRow>
            <LabelTypography>Status:</LabelTypography>
            <ValueTypography>
              {userData["subscription_status"] || "Inactive"}
            </ValueTypography>
          </SubscriptionDetailRow>
        </SubscriptionInfoBox>
      </SubscriptionPaper>
    </SubDetailsContainer>
  );
}

export default SubDetails;

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { styled } from "@mui/material/styles";
import PlanCard from "../subPlanCard_/PlanCard";
import { Box, Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
import { fetchPlansData } from '../../component/data_/SubPlanData'; // Import the fetch function


const StyledMainBox = styled(Box)(({ theme }) => ({
    width: '85vw',
    marginTop: '2rem',
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {
        width: '90vw',
    },
}));

const StyledBox = styled(Box)(({ theme }) => ({
    marginLeft: "2rem",
    marginRight: "2rem",
    [theme.breakpoints.down("sm")]: {
        margin: "0.5rem",
    },
}));

const StyledButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '4rem',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: '20rem',
  borderColor: '#006666',
}));

function PlanSlider() {

  const [plans, setPlans] = useState([]); // State to hold the fetched plans

  // Fetch the plans data when the component mounts
  useEffect(() => {
    async function getPlans() {
      try {
        const fetchedPlans = await fetchPlansData(); // Fetch data from API
        
        setPlans(fetchedPlans); // Set the fetched plans to the state
        console.log(plans);
      } catch (error) {
        console.error('Error fetching plans:', error);
        
      }
    }
    
    
    getPlans(); // Call the function to fetch plans
  }, []); // Empty dependency array to ensure it only runs once on mount


  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <StyledMainBox>
      <Slider {...settings}>
        {plans.map((plan, index) => (
          <Box key={index}>
            <StyledBox>
              <PlanCard {...plan} />
            </StyledBox>
          </Box>
        ))}
      </Slider>

      <StyledButtonBox>
        <StyledButton variant="outlined" ><Link to='/counselling' style={{ textDecoration: 'none', color: '#006666', }} >See All</Link></StyledButton>
      </StyledButtonBox>

    </StyledMainBox>
  );
}

export default PlanSlider;
import React from 'react';
import {
  FooterContainer,
  FooterGridContainer,
  FooterGridItem,
  FooterBox,
  FooterLink,
  FooterTypography,
  FooterFormContainer,
  FooterInput,
  FooterButton,
} from './FooterStyles';
import Logo from '../Logo';
import { Facebook, Instagram, LinkedIn, X } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

const Footer = () => {

  const handleOffset = () => {
    window.scrollTo(0, 0);
  };

  return (
    <FooterContainer>
      <FooterGridContainer container xs={11} justifyContent="center" alignItems="start">
        <FooterGridItem item xs={12} sm={6} md={3}>
          <FooterBox>
            <Logo Width={120} Height={50} />
          </FooterBox>
          <FooterLink to="/" onClick={handleOffset} >Home</FooterLink>
          <FooterLink to="/about_us" onClick={handleOffset} >About Us</FooterLink>
          <FooterLink to="/entrance_exam" onClick={handleOffset} >Entrance Exam</FooterLink>
          <FooterLink to="/counselling" onClick={handleOffset} >Counselling</FooterLink>
          <FooterLink to="/universities" onClick={handleOffset} >Universities</FooterLink>
        </FooterGridItem>

        <FooterGridItem item xs={12} sm={6} md={3}>
          <FooterTypography variant="h6">Support</FooterTypography>
          <FooterLink to="/contact_us" onClick={handleOffset} >Contact Us</FooterLink>
          <FooterLink to="/contact_us" onClick={handleOffset} >Help Center</FooterLink>
          <FooterLink to="/about_us" onClick={handleOffset} >Privacy Policy</FooterLink>
        </FooterGridItem>

        <FooterGridItem item xs={12} sm={6} md={3}>
          <FooterTypography variant="h6">Social Media</FooterTypography>
          <FooterLink to="#">
            <Facebook sx={{ width: 20, height: 20 }} />
            Facebook
          </FooterLink>
          <FooterLink to="#">
            <X sx={{ width: 20, height: 20 }} />
            Twitter
          </FooterLink>
          <FooterLink to="#">
            <Instagram sx={{ width: 20, height: 20 }} />
            Instagram
          </FooterLink>
          <FooterLink to="#">
            <LinkedIn sx={{ width: 20, height: 20 }} />
            LinkedIn
          </FooterLink>
        </FooterGridItem>

        <FooterGridItem item xs={12} sm={6} md={3}>
          <FooterTypography variant="h6">Newsletter</FooterTypography>
          <Typography variant="body2">
            Subscribe to our newsletter to get the latest updates.
          </Typography>
          <FooterFormContainer>
            <form>
              <FooterInput type="email" placeholder="Your email" />
              <FooterButton>Subscribe</FooterButton>
            </form>
          </FooterFormContainer>
        </FooterGridItem>
      </FooterGridContainer>

      <Box textAlign="center">
        <Typography variant="body2" color="inherit">
          © {new Date().getFullYear()} Admission First. All rights reserved.
        </Typography>
      </Box>
    </FooterContainer>
  );
};

export default Footer;

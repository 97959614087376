import React, { useState, useEffect } from "react";
import { Typography, Button, Paper, Box } from "@mui/material";
import axios from "axios"; // Axios for making API requests

const PurchaseRequest = () => {
  const [requests, setRequests] = useState([]);

  // Fetch data from the backend when the component loads
  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await axios.get(
          "https://api.admissionfirst.in/admin_dashboard",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // Assuming the response data contains an array of pending requests
        const pendingRequests = response.data.pending_requests.map((req) => ({
          id: req.id,
          user: req.email,
          transactionId: req.transaction_id,
          subscription: req.subscription,
          status: "Pending"
        }));
        setRequests(pendingRequests);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRequests();
  }, []);

  // Handle Approve
  const handleApprove = async (id, email) => {
    try {
      await axios.post("https://api.admissionfirst.in/admin/approve-transaction", {
        email: email,
        approved: true, // Send 'approved' as true for approving
      });
      setRequests(
        requests.map((req) =>
          req.id === id ? { ...req, status: "Approved" } : req
        )
      );
    } catch (error) {
      console.error("Error approving transaction:", error);
    }
  };

  // Handle Reject
  const handleReject = async (id, email) => {
    try {
      await axios.post("https://api.admissionfirst.in/admin/approve-transaction", {
        email: email,
        approved: false, // Send 'approved' as false for rejecting
      });
      setRequests(
        requests.map((req) =>
          req.id === id ? { ...req, status: "Rejected" } : req
        )
      );
    } catch (error) {
      console.error("Error rejecting transaction:", error);
    }
  };

  return (
    <Box sx={{ width: "70vw", height: "auto", m: 1 }}>
      <Box sx={{ width: "100%" }}>
        <Paper
          elevation={8}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Box sx={{ m: 2, width: "100%" }}>
            <Typography variant="h6" gutterBottom sx={{ color: "#006666" }}>
              Purchase Requests :
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ width: "25%", fontWeight: "600" }}>
                  User
                </Typography>
                <Typography sx={{ width: "25%", fontWeight: "600" }}>
                  Transaction ID
                </Typography>
                <Typography sx={{ width: "25%", fontWeight: "600" }}>
                  Plan
                </Typography>
                <Typography sx={{ width: "25%", fontWeight: "600" }}>
                  Status
                </Typography>
                <Typography
                  align="center"
                  sx={{ width: "25%", fontWeight: "600" }}
                >
                  Actions
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {requests.map((request) => (
                  <Box
                    key={request.id}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ width: "25%" }}>
                      {request.user}
                    </Typography>
                    <Typography sx={{ width: "25%" }}>
                      {request.transactionId}
                    </Typography>
                    <Typography sx={{ width: "25%" }}>
                      {request.subscription}
                    </Typography>
                    <Typography sx={{ width: "25%" }}>
                      {request.status}
                    </Typography>
                    <Typography align="center" sx={{ width: "25%" }}>
                      {request.status === "Pending" ? (
                        <>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() =>
                              handleApprove(request.id, request.user)
                            }
                            sx={{ marginRight: 1 }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() =>
                              handleReject(request.id, request.user)
                            }
                          >
                            Reject
                          </Button>
                        </>
                       ) : ( 
                         <Typography>{request.plan}</Typography>
                       )}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default PurchaseRequest;

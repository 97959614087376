import { styled } from "@mui/material/styles";
import { AppBar, Box, Button, MenuItem, Typography, Avatar, IconButton } from "@mui/material";
import { Link } from "react-router-dom";

// Styled components
export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#fff",
  position: "fixed",
}));

export const StyledNavBar = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("1000")]: {
    display: "none",
  },
}));

export const StyledSmallNavBar = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.up("1200")]: {
    display: "none",
  },
  [theme.breakpoints.down("1000")]: {
    width: '95vw',
  },
  // width: '97vw', // Default for small screens
  [theme.breakpoints.down("850")]: {
    width: '90vw', // Adjust for medium-small screens
  },
  [theme.breakpoints.between("700", "800")]: {
    width: "95vw",
  },
  [theme.breakpoints.down("700")]: {
    width: '100vw', // Full width for mobile
  },
  [theme.breakpoints.down("400")]: {
    width: '90vw', // Full width for mobile
  },
}));

export const StyledButton = styled(Button)(({ theme,  }) => ({
  marginLeft: '5px',
  marginRight: '5px',
  fontSize: "1rem",
  textDecoration: "none",
  [theme.breakpoints.between("1000", "1200")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.between("850", "1000")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.between("600", "850")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.between("400", "600")]: {
    fontSize: "0.7rem",
  },
  [theme.breakpoints.down("400")]: {
    fontSize: "0.6rem",
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: "none",
    marginLeft: "10px",
    marginRight: "10px",
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  textAlign: "center",
}));

export const StyledAvatarButton = styled(Box)(({ theme }) => ({
  width: "6vw",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.between("850", "1000")]: {
    width: "8vw",
  },
  [theme.breakpoints.between("700", "850")]: {
    width: "30%",
  },
  [theme.breakpoints.between('600', '700')]: {
    width: "30%",
  },
  [theme.breakpoints.between("500", "600")]: {
    width: "20vw",
  },
  [theme.breakpoints.down("500")]: {
    width: "25vw",
  },
}));

export const StyledUserTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
}));

export const StyledUserDropdown = styled(Box)(({ theme }) => ({
  width: "12vw",
  height: "10vh",
  backgroundColor: "white",
  position: "absolute",
  borderRadius: "2px",
  marginTop: theme.spacing(20),
  marginRight: theme.spacing(-3),
  gap: theme.spacing(1),
  boxShadow: "0px 0px 7px 1px grey",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "center",
  [theme.breakpoints.down("1200")]: {
    width: "15vw",
  },
  [theme.breakpoints.down("1000")]: {
    width: "14vw",
    height: '5vh',
    marginTop: theme.spacing(18),
    marginRight: theme.spacing(4),
  },
  [theme.breakpoints.down("850")]: {
    width: "13vw",
    marginTop: theme.spacing(18),
    marginRight: theme.spacing(-8),
    gap: theme.spacing(0.2),
  },
  [theme.breakpoints.between("700", "800")]: {
    marginTop: theme.spacing(17),
    marginRight: theme.spacing(-9),
  },
  [theme.breakpoints.down("700")]: {
    width: "25vw",
    height: '7vh',
  },
  [theme.breakpoints.down("600")]: {
    width: "26vw",
    height: '6vh',
    marginTop: theme.spacing(16),
    marginRight: theme.spacing(3),
    gap: theme.spacing(0.2),
  },
  [theme.breakpoints.down("400")]: {
    width: "26vw",
    height: '6vh',
    marginTop: theme.spacing(17),
    marginRight: theme.spacing(-6),
    gap: theme.spacing(0.2),
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 600,
  color: "#006666",
  display: "flex",
  alignItems: "center",
  marginLeft: theme.spacing(1),
  gap: theme.spacing(2),
  [theme.breakpoints.down("1200")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.between("850", "1000")]: {
    fontSize: "0.8rem",
    gap: theme.spacing(1),
  },
  [theme.breakpoints.between("600", "850")]: {
    fontSize: "0.7rem",
    gap: theme.spacing(0.8),
  },
  [theme.breakpoints.between("400", "600")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.down("400")]: {
    fontSize: "0.5rem",
    gap: theme.spacing(1),
  },
}));

export const StyledSignInButton = styled(Box)(({ theme }) => ({
  width: "10vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.between("1000", "1200")]: {
    width: "12vw",
  },
  [theme.breakpoints.between("850", "1000")]: {
    width: "16vw",
  },
  [theme.breakpoints.between("600", "850")]: {
    width: "20vw",
  },
  [theme.breakpoints.between("400", "600")]: {
    width: "25vw",
  },
  [theme.breakpoints.down("400")]: {
    width: "30vw",
  },
}));

export const StyledMenuIcon = styled(IconButton)(({ theme }) => ({
  color: "black",
  padding: 0,
  width: "20vw",
  display: "flex",
  justifyContent: "left",
  [theme.breakpoints.down("1000")]: {
    width: "10%",
  },
  [theme.breakpoints.down("850")]: {
    width: "20%",
  },
  [theme.breakpoints.down("600")]: {
    width: "20vw",
  },
  [theme.breakpoints.down("400")]: {
    width: "30%",
  },
}));
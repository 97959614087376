import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Container, IconButton, Typography, Avatar, Hidden, Menu } from "@mui/material";
import { AccountCircle, Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../Logo";
import {
  StyledAppBar,
  StyledNavBar,
  StyledSmallNavBar,
  StyledButton,
  StyledMenuItem,
  StyledAvatarButton,
  StyledLink,
  StyledUserTypography,
  StyledUserDropdown,
  StyledTypography,
  StyledSignInButton,
  StyledMenuIcon,
} from "./NavbarStyles"; // Import styled components

const navItems = [
  { name: "Home", path: "/" },
  { name: "About Us", path: "/about_us" },
  { name: "Entrance Exam", path: "/entrance_exam" },
  { name: "Counselling", path: "/counselling" },
  { name: "Universities", path: "/universities" },
  { name: "Contact Us", path: "/contact_us" },
];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [loggedUser, setLoggedUser] = useState("");
  const [userRole, setUserRole] = useState("");
  const [navUser, setNavUser] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");

    if (storedUser) {
      const user = JSON.parse(storedUser);
      setLoggedUser(user["fname"]);
      setUserRole(user["role"]);
    }
  }, []);

  const toggleNavUser = () => {
    setNavUser(!navUser);
  };

  const redirectSignIn = () => {
    navigate("/signin");
  };

  const logout = () => {
    sessionStorage.removeItem("user");
    setLoggedUser(null);
    setUserRole(null);
    setNavUser(false);
    sessionStorage.removeItem('hasRefreshed', 'false'); 
    navigate("/signin");
  };

  const handleUserProfile = () => {
    const profilePath = userRole === "user" || userRole === "premium" ? `/profile/${loggedUser.toLowerCase()}` : `/admin/${loggedUser.toLowerCase()}`;
    navigate(profilePath);
    setNavUser(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOffset = () => {
    setAnchorElNav(null);
    window.scrollTo(0, 0);
  };

  return (
    <StyledAppBar>
      <Container maxWidth="xl">
        <StyledNavBar>
          <Hidden mdDown>
            <Link to="/">
              <Logo Width={120} Height={50} />
            </Link>
          </Hidden>

          <Box sx={{ display: "flex" }}>
            {navItems.map((items, index) => {
              const path = items.path;
              const isActive = location.pathname === path;
              return (
                <StyledButton key={index} 
                  sx={{                    
                    backgroundColor: isActive ? "#f0f0f0" : "transparent",
                   }}
                >
                  <StyledLink
                    to={path}
                    onClick={handleOffset}
                    style={{ 
                      color: isActive ? "#006666" : "black",
                    }}   
                  >
                    {items.name}
                  </StyledLink>
                </StyledButton>
              );
            })}
          </Box>

          <IconButton disableRipple sx={{ mr: 0, display: 'flex', justifyContent: 'right', "&:hover": { bgcolor: "transparent" } }}>
            {loggedUser ? (
              <StyledAvatarButton onClick={toggleNavUser}>
                <Typography sx={{ my: 2, mx: 2, color: "#006666", fontWeight: 600 }}>
                  {loggedUser}
                </Typography>
                <Avatar alt={loggedUser} src="/static/images/avatar/2.jpg" />
              </StyledAvatarButton>
            ) : (
              <StyledSignInButton onClick={redirectSignIn}>
                <Typography sx={{ my: 2, mx: 2, color: "#006666", fontSize: "medium" }}>
                  Sign In
                </Typography>
              </StyledSignInButton>
            )}
            {navUser && (
              <StyledUserDropdown>
                <StyledTypography variant="h8" onClick={handleUserProfile}>
                  <AccountCircle />
                  Dashboard
                </StyledTypography>
                <StyledTypography variant="h8" onClick={logout}>
                  <Logout />
                  Logout
                </StyledTypography>
              </StyledUserDropdown>
            )}
          </IconButton>
        </StyledNavBar>

        <StyledSmallNavBar>
          <StyledMenuIcon size="large" onClick={handleOpenNavMenu}>
            <MenuIcon/>
          </StyledMenuIcon>
          <Link to="/">
            <Logo Width={120} Height={50} />
          </Link>

          <IconButton disableRipple sx={{ mr: 0, "&:hover": { bgcolor: "transparent" } }}>
            {loggedUser ? (
              <StyledAvatarButton onClick={toggleNavUser}>
                <StyledUserTypography sx={{ my: 2, mx: 2, color: "#006666", fontWeight: 600, }}>
                  {loggedUser}
                </StyledUserTypography>
                <Avatar alt={loggedUser} src="/static/images/avatar/2.jpg" />
              </StyledAvatarButton>
            ) : (
              <StyledSignInButton onClick={redirectSignIn}>
                <Typography sx={{ my: 2, mx: 2, color: "#006666", fontSize: "medium" }}>
                  Sign In
                </Typography>
              </StyledSignInButton>
            )}
            {navUser && (
              <StyledUserDropdown>
                <StyledTypography variant="h8" onClick={handleUserProfile}>
                  <AccountCircle />
                  Dashboard
                </StyledTypography>
                <StyledTypography variant="h8" onClick={logout}>
                  <Logout />
                  Logout
                </StyledTypography>
              </StyledUserDropdown>
            )}
          </IconButton>

          <Menu
            anchorEl={anchorElNav}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {navItems.map((items, index) => (
              <StyledMenuItem key={index}>
                <Link
                  to={items.path}
                  onClick={handleOffset}
                  style={{ textDecoration: "none", color: "#006666", marginLeft: "10px", marginRight: "10px" }}
                >
                  {items.name}
                </Link>
              </StyledMenuItem>
            ))}
          </Menu>
        </StyledSmallNavBar>
      </Container>
    </StyledAppBar>
  );
}

export default Navbar;

import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const ResponsiveBox = styled(Box)(({ theme }) => ({
  marginTop: '2.6rem',
  padding: '2rem',
  width: '94vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('500')]: {
    padding: '0.7rem',
  },
  [theme.breakpoints.down(1000)]: {
    width: '89vw',
  },
}));

export const HeadContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // backgroundColor: 'green'
}));

export const HeaderImage = styled(Box)(({ theme }) => ({
  width: '98vw',
  height: '80vh',
  objectFit: 'cover',
  backgroundColor: '#000',
  filter: 'brightness(0.9)',
  [theme.breakpoints.down('sm')]: {
    height: '25vh',
  }
}));

export const PlanContainerBox = styled(Box)(({ theme }) => ({
  padding: '2rem',
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  columnGap: '10rem',
  justifyContent: 'center',
  [theme.breakpoints.down(1000)]: {
    gridTemplateColumns: 'auto auto',
    columnGap: '5rem',
  },
  [theme.breakpoints.down(500)]: {
    gridTemplateColumns: 'auto',
    columnGap: 0,
  },
  [theme.breakpoints.down(850)]: {
    columnGap: '2rem',
  },
}));

import React, { useState, useEffect } from "react";
import {
  RootContainer,
  HeaderContainer,
  HeaderImage,
  TextOverlay,
  MainContainer,
  InnerContainer,
  SearchContainer,
  DropdownContainer,
  UniversityName,
  PaperContainer,
  CoursesWrapper,
  CoursesInnerBox,
  ColumnWrapper,
  ColumnTitle,
  DividerStyled,
  DividerVerticalStyled,
  CoursesGrid,
  CourseItem,
  VisitWebsiteButton,
  BottomDivider,
} from "./UniversitiesStyles"; // Import the styles
import DropdownSearch from "../../component/DropdownSearch";
import { Box, Typography } from "@mui/material";

import HeaderImg from '../../assets/university.jpg'

function Universities() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [default_, setDefault_] = useState();

  useEffect(() => {
    fetch("/data.json")
      .then((response) => response.json())
      .then((data) => {
        setData(data.courses);
        setFilteredData(data.courses);
        setDefault_(data.courses[0]);
        setSelectedUniversity(data.courses[0]);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleSearch = (event, value) => {
    setSearchTerm(value);
    const filtered = data.filter((university) =>
      university.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleUniversitySelect = (event, value) => {
    if (value === null) {
      setSelectedUniversity(default_);
    } else {
      const selected = data.find((university) => university.name === value);
      setSelectedUniversity(selected);
    }
  };

  return (
    <RootContainer>

      <HeaderContainer>
        <HeaderImage component="img" src={HeaderImg} />
        <TextOverlay>
          <Typography variant="h2" align="center" sx={{ mt: 5, fontSize: { lg: '4rem', sm: '3rem', xs: '2rem' }, color: '#fff', fontWeight: '700' }}>
            Universities List
          </Typography>
        </TextOverlay>
      </HeaderContainer>

      <MainContainer>

      {selectedUniversity && (
        <InnerContainer>
          <SearchContainer>
            <DropdownContainer>
              <DropdownSearch
                data={data}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                handleUniversitySelect={handleUniversitySelect}
              />
            </DropdownContainer>

            <UniversityName variant="h4">
              {selectedUniversity.name}
            </UniversityName>
          </SearchContainer>

          <CoursesWrapper>
            <PaperContainer elevation={4}>
              <CoursesInnerBox>
                <ColumnWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <ColumnTitle>Under Graduation Courses</ColumnTitle>
                    <DividerStyled />
                  </Box>
                  <CoursesGrid>
                    {selectedUniversity["Undergraduation"].map(
                      (course, index) => (
                        <CourseItem key={index}>
                          <Typography variant="h7">{course}</Typography>
                        </CourseItem>
                      )
                    )}
                  </CoursesGrid>
                </ColumnWrapper>

                <DividerVerticalStyled />

                <ColumnWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <ColumnTitle>Post Graduation Courses</ColumnTitle>
                    <DividerStyled />
                  </Box>
                  <CoursesGrid>
                    {selectedUniversity["Postgraduation"].map(
                      (course, index) => (
                        <CourseItem key={index}>
                          <Typography variant="h7">{course}</Typography>
                        </CourseItem>
                      )
                    )}
                  </CoursesGrid>
                </ColumnWrapper>
              </CoursesInnerBox>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <VisitWebsiteButton
                  size="small"
                  href={selectedUniversity.url}
                  target="_blank"
                >
                  Visit Website
                </VisitWebsiteButton>
                <BottomDivider />
              </Box>
            </PaperContainer>
          </CoursesWrapper>
        </InnerContainer>
      )}
      </MainContainer>
    </RootContainer>
  );
}

export default Universities;
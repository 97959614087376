import { styled } from '@mui/material/styles';
import { Card, CardHeader, Typography, Box, Button } from '@mui/material';
import { Chip } from '@mui/joy/';

// Custom styled components
export const StyledCard = styled(Card)(({ theme }) => ({
  width: '22vw',
  maxHeight: '84vh',
  borderWidth: 2,
  borderColor: '#008080',
  backgroundColor: 'transparent',
  
  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    width: '25vw',
    maxHeight: '80vh',
  },
  [theme.breakpoints.between('850', '1000')]: {
    width: '30vw',
    maxHeight: '75vh',
  },
  [theme.breakpoints.between('600', '850')]: {
    width: '40vw',
    maxHeight: '70vh',
  },
  [theme.breakpoints.between('600', '700')]: {
    width: '50vw',
    maxHeight: '65vh',
  },
  [theme.breakpoints.between('500', '600')]: {
    width: '70vw',
    maxHeight: '60vh',
  },
  [theme.breakpoints.down('500')]: {
    width: '80vw',
    maxHeight: '70vh',
  },
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(1),
  color: '#000',
  backgroundColor: '#f0f0f0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.down('600')]: {
    fontSize: '0.8rem',
  },
}));

export const StyeledBox = styled(Box)(({ theme }) => ({
  height: '20vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    height: '18vh',
  },
  [theme.breakpoints.down('600')]: {
    height: '15vh',
  },
}));

export const StyledTitle = styled(CardHeader)(({ theme }) => ({
  textAlign: 'center',
  '& .MuiCardHeader-title': {
    fontWeight: 900,
    color: '#006666',
    fontSize: theme.typography.pxToRem(20),

    // Responsive adjustments
    [theme.breakpoints.between('1000', '1200')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    [theme.breakpoints.between('600', '1000')]: {
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.down('600')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  color: '#008080',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: theme.typography.pxToRem(13),
  },
  [theme.breakpoints.down('600')]: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

export const StyledCourses = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  color: '#000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.down('600')]: {
    fontSize: '0.8rem',
  },
}));

export const ContentTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  margin: theme.spacing(1),

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    margin: theme.spacing(0.8),
  },
  [theme.breakpoints.down('600')]: {
    margin: theme.spacing(0.5),
  },
}));

export const PriceTypography = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 900,
  color: '#006666',

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.between('600', '1000')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('600')]: {
    fontSize: '2rem',
  },
}));

export const TextTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  color: '#008080',

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.down('600')]: {
    fontSize: '0.8rem',
  },
}));

export const FeatureList = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  color: '#000',
  '& ul': {
    listStyleType: 'none',
    padding: 0,
  },
  '& li': {
    margin: theme.spacing(2),
    fontSize: 'large',
    display: 'flex',
    alignItems: 'center',

    // Responsive adjustments
    [theme.breakpoints.between('1000', '1200')]: {
      margin: theme.spacing(1.5),
      fontSize: 'medium',
    },
    [theme.breakpoints.between('600', '1000')]: {
      margin: theme.spacing(1),
      fontSize: 'small',
    },
    [theme.breakpoints.down('600')]: {
      margin: theme.spacing(0.5),
      fontSize: 'smaller',
    },
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#008080',
  '&:hover': { backgroundColor: '#006666' },
  width: '100%',

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: 'medium',
  },
  [theme.breakpoints.down('600')]: {
    fontSize: 'small',
  },
}));
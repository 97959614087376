import { styled } from "@mui/material/styles";
import { Box, Paper, Typography, Divider } from "@mui/material";

// Box wrapper styling
export const SubDetailsContainer = styled(Box)(({ theme }) => ({
  width: "70vw",
  height: "auto",
  margin: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

// Paper styling for the subscription details card
export const SubscriptionPaper = styled(Paper)(({ theme }) => ({
  width: "95%",
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  borderRadius: "12px",
}));

// Title Typography
export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: 600,
  color: "#006666",
  marginBottom: theme.spacing(2),
}));

// Divider styling
export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

// Subscription Info Box styling
export const SubscriptionInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

// Row for subscription detail (label and value)
export const SubscriptionDetailRow = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

// Label Typography styling
export const LabelTypography = styled(Typography)({
  fontWeight: 500,
  color: "#333",
});

// Value Typography styling
export const ValueTypography = styled(Typography)({
  fontWeight: 600,
});

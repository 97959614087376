import React, { useState } from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import ServicesCard from '../ServicesCard';
import servicesData from '../../pages/services_/ServiceData';
import { CarouselContainer, CarouselBox, StyledTitleBox, StyledBox, ButtonContainer, RotateButton } from "./ServicesSectionStyles"; 


const ServicesSection = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const translateZValue = isMobile ? '12rem' : '40rem';

  const handlePrevClick = () => {
    setSelectedIndex((prevIndex) => prevIndex - 1);
  };

  const handleNextClick = () => {
    setSelectedIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <Box>
      <CarouselContainer>
        <StyledTitleBox><Typography variant='h6' sx={{ fontWeight: '600' }} >Our Services</Typography></StyledTitleBox>
        <CarouselBox selectedIndex={selectedIndex} translateZValue={translateZValue}>
          {servicesData.map((service, index) => (
            <StyledBox
              key={index}
              sx={{
                transform: `rotateY(${index * 40}deg) translateZ(${translateZValue})`,
                zIndex: selectedIndex === index ? 1 : 0,
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ServicesCard {...service} />
              </div>
            </StyledBox>
          ))}
        </CarouselBox>
        <ButtonContainer>
          <RotateButton variant="contained" onClick={handlePrevClick} rotate={true}>
            <ArrowRightAlt />
          </RotateButton>
          <RotateButton variant="contained" onClick={handleNextClick}>
            <ArrowRightAlt />
          </RotateButton>
        </ButtonContainer>
      </CarouselContainer>
    </Box>
  );
};

export default ServicesSection;
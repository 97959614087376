import { styled } from '@mui/system';
import { Box, Typography, Card, CardContent, CardMedia } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0',
  padding: '1rem 0',
  width: '100%',

  // Responsive adjustments
  [theme.breakpoints.between('1000', '1200')]: {
    padding: '1rem 2rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    padding: '1rem 1.5rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    padding: '1rem 1rem',
  },
  [theme.breakpoints.down('600')]: {
    padding: '1rem 0.5rem',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginTop: '1rem',
  marginBottom: '1rem',
  fontSize: 'large',
  fontWeight: 600,
  color: '#006666',

  // Responsive font size
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    fontSize: '1.6rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.between('400', '600')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.down('400')]: {
    fontSize: '1rem',
  },
}));

export const CarouselBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  // Responsive gap adjustment
  [theme.breakpoints.between('600', '850')]: {
    gap: theme.spacing(2.5),
  },
  [theme.breakpoints.down('600')]: {
    gap: theme.spacing(2),
  },
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '15rem',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  // Responsive card width
  [theme.breakpoints.between('1000', '1200')]: {
    width: '14rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    width: '13rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    width: '12rem',
  },
  [theme.breakpoints.down('600')]: {
    width: '11rem',
  },
  [theme.breakpoints.down('400')]: {
    width: '10rem',
  },
}));

export const Media = styled(CardMedia)(({ theme }) => ({
  width: '5rem',
  height: '5rem',
  borderRadius: '50%',
  marginTop: '16px',

  // Responsive media size
  [theme.breakpoints.between('1000', '1200')]: {
    width: '4.5rem',
    height: '4.5rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    width: '4rem',
    height: '4rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    width: '3.5rem',
    height: '3.5rem',
  },
  [theme.breakpoints.down('600')]: {
    width: '3rem',
    height: '3rem',
  },
}));

export const TriangleBox = styled(Box)(({ theme }) => ({
  width: '1rem',
  height: '1rem',
  marginTop: '16px',
  marginBottom: '-8px',
  backgroundColor: '#008080',
  transform: 'rotate(45deg)',

  // Responsive triangle size
  [theme.breakpoints.between('1000', '1200')]: {
    width: '0.9rem',
    height: '0.9rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    width: '0.8rem',
    height: '0.8rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    width: '0.7rem',
    height: '0.7rem',
  },
  [theme.breakpoints.down('600')]: {
    width: '0.6rem',
    height: '0.6rem',
  },
}));

export const Content = styled(CardContent)(({ theme }) => ({
  width: '100%',
  height: '5rem',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#008080',

  // Responsive content height
  [theme.breakpoints.between('1000', '1200')]: {
    height: '4.8rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    height: '4.6rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    height: '4.4rem',
  },
  [theme.breakpoints.down('600')]: {
    height: '4rem',
  },
}));

export const ReviewText = styled(Typography)(({ theme }) => ({
  width: '95%',
  color: '#fff',

  // Responsive text size
  [theme.breakpoints.between('1000', '1200')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.between('850', '1000')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.between('600', '850')]: {
    fontSize: '0.85rem',
  },
  [theme.breakpoints.down('600')]: {
    fontSize: '0.8rem',
  },
}));
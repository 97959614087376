import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";

const ButtonsContainer = styled(Box)(({ theme }) => ({
//   width: "100vw", // Full width on mobile
  height: "auto", // Height auto to fit the content
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

// Grid for buttons, optimized for mobile
const ButtonsGrid = styled(Box)(({ theme }) => ({
  width: "90%",
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)", // Two columns on mobile
  gap: theme.spacing(2), // Adjust gap between buttons

  // Styles for larger screens
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(4, 1fr)", // Four columns for larger screens
    gap: theme.spacing(3),
  },
}));

// Styled button with responsive background color
const StyledButton = styled(Button)(({ theme }) => ({
  height: '5vh',
  bgcolor: "#12c9ff",
  fontSize: '0.6rem',
  "&:hover": {
    bgcolor: "#0fa3d5", // Darker shade on hover
  },
  // For different button colors, you can set specific props or className in the component
}));

function ButtonsMobile() {
  return (
    <ButtonsContainer>
      <ButtonsGrid>
        <StyledButton variant="contained">Admission Process</StyledButton>
        <StyledButton variant="contained" sx={{ bgcolor: "#a7b75a" }}>
          Documents & criteria
        </StyledButton>
        <StyledButton variant="contained" sx={{ bgcolor: "#3b43d6" }}>
          Seat Matrix
        </StyledButton>
        <StyledButton variant="contained" sx={{ bgcolor: "#dd3439" }}>
          Merit List
        </StyledButton>
        <StyledButton variant="contained" sx={{ bgcolor: "#c29d4f" }}>
          Previous Year Cut-Off
        </StyledButton>
        <StyledButton variant="contained" sx={{ bgcolor: "#d63b7e" }}>
          Institutes
        </StyledButton>
        <StyledButton variant="contained" sx={{ bgcolor: "#b056bb" }}>
          Round Alerts
        </StyledButton>
      </ButtonsGrid>
    </ButtonsContainer>
  );
}

export default ButtonsMobile;

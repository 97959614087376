// Ratings.js
import * as React from 'react';
import { Typography } from '@mui/material';
import {
  StyledContainer,
  StyledDivider,
  StyledTypography,
  StyledRatingContainer,
  StyledRating,
} from './RatingsStyles';

function Ratings() {
  return (
    <StyledContainer>
      <StyledDivider sx={{ mb: 2 }} />

      <StyledTypography>
        <p>User Ratings</p>
      </StyledTypography>

      <StyledRatingContainer>
        <StyledRating name="read-only" value={4} readOnly />
      </StyledRatingContainer>

      <Typography variant='h6'>Trusted by many of you!</Typography>

      <StyledDivider sx={{ mb: 2 }} />
    </StyledContainer>
  );
}

export default Ratings;
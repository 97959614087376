import React from "react";
import { Link } from "react-router-dom";
import {
  StyledContainer,
  StyledImage,
  StyledTypography,
  ButtonContainer,
  VisitButton,
  DownloadButton,
} from "./EntranceCardStyles";

function EntranceCard({ cardImg, examName, website, exam_document }) {
  
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = exam_document;
    link.download = exam_document.split('/').pop();; // Specify the filename for the download
    link.click();
  };
  
  return (
    <>
      <StyledContainer>
        <StyledImage component="img" src={cardImg} />
        <StyledTypography>{examName}</StyledTypography>
        <ButtonContainer>
          <VisitButton variant="contained" disableElevation>
            <Link to={website} target="blank" style={{ textDecoration: 'none', color: 'white' }} >
              Visit
            </Link>
          </VisitButton>
          
          <DownloadButton variant="contained" disableElevation onClick={handleDownload} >
            Download
          </DownloadButton>
        </ButtonContainer>
      </StyledContainer>
    </>
  );
}

export default EntranceCard;

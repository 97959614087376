import { styled } from '@mui/material/styles';
import { Avatar, Paper, Box, Grid } from '@mui/material';

// Fullscreen background grid to center the content
export const BackgroundGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: 'black',
  height: '90vh', // Full viewport height
  display: 'flex',
  justifyContent: 'center', // Center horizontally
  alignItems: 'center', // Center vertically
  
}));

// Responsive SignInForm
export const SignInForm = styled(Paper)(({ theme }) => ({
  width: '70%', // Default width for smaller screens
  maxWidth: '400px', // Set max width for larger screens
  padding: theme.spacing(4), // Add padding for spacing
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop:"3rem",
  [theme.breakpoints.up('sm')]: {
    width: '50%', // Set width for medium screens and above
  },
}));

// Avatar styling
export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

// FormBox container for the form
export const FormBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

// MainBox to ensure layout centering and responsiveness
export const MainBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh', // Full viewport height
  display: 'flex',
  justifyContent: 'center', // Center horizontally
  alignItems: 'center', // Center vertically
  backgroundColor: '#abb4e1',
  [theme.breakpoints.down(1000)]: {
    width: '97vw',
  },
  [theme.breakpoints.down(500)]: {
    width: '100vw',
  },
}));
import { styled } from '@mui/material/styles';
import { Avatar, Paper, Box, Grid } from '@mui/material';


export const BackgroundGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  }
}));

export const SignInForm = styled(Box)(({ theme }) => ({
  paddingRight: '2rem',
  paddingLeft: '2rem',
  marginRight: '1rem',
  marginTop: '3.5rem',
  marginBottom: '1rem',
  width: '40%',
  height: '32rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down(1000)]: {
    width: '95vw',
    height: '50rem',
    margin: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  [theme.breakpoints.down(500)]: {
    width: '95vw',
    height: '32rem',
    margin: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
}));

export const AvatarStyled = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
  marginTop: '1rem',
}));

export const FormBox = styled(Box)(({ theme }) => ({
  // marginTop: '-3rem',
}));

export const MainBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#abb4e1',
  [theme.breakpoints.down(1000)]: {
    justifyContent: 'center',
  }
}));
import React from "react";
import A1 from "../../../assets/A1.png";
import {
  StyledBox,
  StyledPaper,
  ImageBox,
  UserImage,
  UserNameTypography,
  InfoBox,
  InfoTitle,
  DetailBox,
  InfoRow,
  InfoLabel,
  InfoValue,
  EditButtonBox,
  EditButton,
} from "./UserInfoStyles";

function UserInfo({ handleClickOpen, userData }) {
  return (
    <StyledBox>
      <StyledPaper elevation={8}>
        <ImageBox>
          <UserImage component="img" src={A1} />
          <UserNameTypography variant="h5">
            {userData["fname"]} {userData["lastname"]}
          </UserNameTypography>
        </ImageBox>
        <InfoBox>
          <InfoTitle>Basic Information</InfoTitle>
          <DetailBox>
            <InfoRow>
              <InfoLabel>AGE :</InfoLabel>
              <InfoValue>{userData["age"]}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>EDUCATION :</InfoLabel>
              <InfoValue>{userData["education"]}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>PHONE :</InfoLabel>
              <InfoValue>+91 {userData["mobile_number"]}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>EMAIL :</InfoLabel>
              <InfoValue>{userData["email"]}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>CITY :</InfoLabel>
              <InfoValue>{userData["city"]}</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoLabel>STATE :</InfoLabel>
              <InfoValue>{userData["state"]}</InfoValue>
            </InfoRow>
          </DetailBox>
        </InfoBox>
        <EditButtonBox>
          <EditButton variant="outlined" onClick={handleClickOpen}>
            Edit Profile
          </EditButton>
        </EditButtonBox>
      </StyledPaper>
    </StyledBox>
  );
}

export default UserInfo;
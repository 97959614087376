import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const MainBox = styled(Box)(({ theme }) => ({
  width: '98vw',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  alignItems: 'center',
}));

export const HeadContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '4.1rem',
  // backgroundColor: 'green'
}));

export const HeaderImage = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '80vh',
  objectFit: 'cover',
  backgroundColor: '#000',
  [theme.breakpoints.down('sm')]: {
    height: '25vh',
  }
}));

export const SectionContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '50rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // justifyContent: 'space-between',
  gap: '1rem',
  marginTop: '3rem',
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  width: '90%',
  backgroundColor: 'lavender',
  padding: theme.spacing(1, 0),
  textAlign: 'center',
  color: '#008080',
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto auto',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  gap: '1rem',
  width: '90%',
  height: 'auto',
  [theme.breakpoints.down(1000)]: {
    gridTemplateColumns: 'auto auto auto',
    width: '100%',
  },
  [theme.breakpoints.down(600)]: {
    gridTemplateColumns: 'auto auto',
    width: '100%',
  },
}));

export const CardWrapper = styled(Box)(({ theme }) => ({
}));
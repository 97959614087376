import React from "react";
import Logo__ from "../assets/New Logo.JPG";
import "@mui/material";
import { Box } from "@mui/material";

function Logo( { Width, Height } ) {
  return (
    <Box
      component="img" src={Logo__}
      sx={{
        height: Height, //50
        width: Width,   //120
        // mr: 1,
        // ml: -3,
        bgcolor: '#fff',
        borderRadius: '5px',
      }}
    />
  );
}

export default Logo;

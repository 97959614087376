// import A3 from '../../assets/A3.png'

import { TextSnippet, FactCheck, Campaign, ListAlt, Apartment, Margin, PriceChange, WifiProtectedSetup } from '@mui/icons-material'

const servicesData = [
    {
      icon: <TextSnippet sx={{ fontSize: {md: '2rem', lg: '3rem'}, color: '#fff' }} />,
      service: "Documents",
      info: "Give All Related Required Documents & Certificate Of Formats For Your Enrolled Programs",
    },
    {
      icon: <FactCheck sx={{ fontSize: {md: '2rem', lg: '3rem'}, color: '#fff' }} />,
      service: "Eligibility Criteria",
      info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, consequuntur?",
    },
    {
      icon: <Campaign sx={{ fontSize: {md: '2rem', lg: '3rem'}, color: '#fff' }} />,
      service: "Announcement Alerts",
      info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, consequuntur?",
    },
    {
      icon: <ListAlt sx={{ fontSize: {md: '2rem', lg: '3rem'}, color: '#fff' }} />,
      service: "Cut-Off",
      info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, consequuntur?",
    },
    {
      icon: <Apartment sx={{ fontSize: {md: '2rem', lg: '3rem'}, color: '#fff' }} />,
      service: "College/Institutes",
      info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, consequuntur?",
    },
    {
      icon: <Margin sx={{ fontSize: {md: '2rem', lg: '3rem'}, color: '#fff' }} />,
      service: "Seat Matrix",
      info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, consequuntur?",
    },
    {
      icon: <PriceChange sx={{ fontSize: {md: '2rem', lg: '3rem'}, color: '#fff' }}/>,
      service: "Fee Structure",
      info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, consequuntur?",
    },
    {
      icon: <Apartment sx={{ fontSize: {md: '2rem', lg: '3rem'}, color: '#fff' }} />,
      service: "College/Institutes",
      info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, consequuntur?",
    },
    {
      icon: <WifiProtectedSetup sx={{ fontSize: {md: '2rem', lg: '3rem'}, color: '#fff' }} />,
      service: "Round Alerts",
      info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, consequuntur?",
    }
  ];


  export default servicesData;